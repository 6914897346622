import React, { ChangeEvent, Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Store } from "redux";
import { isChrome, isIOS } from "react-device-detect";

import VideoIcon from "static/img/video.png";
import UploadIcon from "static/img/upload.png";
import ModelIcon from "static/img/model.png";
import MainBG from "static/img/main_page_bg.png";
import { ROUTES } from "utils/constants";
import {
  changeIsLiveStatus,
  putCurrentModel,
  selectIsAnalyticRequired,
  selectLiveMakeUpButtonError,
  selectLiveMakeUpButtonText,
  selectModelButtonText,
  selectUploadButtonText
} from "ducks/app";
import { initImpression } from "ducks/analytics";
import { ActionType, ModelDataType } from "types/common";

import styles from "./MainPageContent.module.scss";
import { FormattedMessage } from "react-intl";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

interface Props extends RouteComponentProps {
  isAnalyticRequired: boolean;
  mainScreenTitle: string;
  mainScreenDescription: string;
  mainScreenDisclaimer: string;
  liveMakeUpButtonText: string;
  liveMakeUpButtonError: string;
  uploadButtonText: string;
  modelButtonText: string;
  putCurrentModel: (model: ModelDataType) => ActionType;
  changeIsLiveStatus: (payload: boolean) => ActionType;
  initImpression: (payload: string) => ActionType;
}

class MainPageContent extends Component<Props> {
  state = {
    openUpload: false,
    openLive:false,
    openModel:false
  };

  onOpenModalUpload = () => {
    this.setState({openUpload: true, openLive: false, openModel: false});
  };

  onCloseModalUpload = () => {
    this.setState({openUpload: false, openLive: false, openModel: false});
  };
  onOpenModalLive = () => {
    this.setState({openLive: true, openUpload: false, openModel: false});
  };

  onCloseModalLive = () => {
    this.setState({openLive: false, openUpload: false, openModel: false});
  };

  onOpenModalModel = () => {
    this.setState({openModel: true, openUpload: false, openLive: false});
  };

  onCloseModalModel = () => {
    this.setState({openModel: false, openUpload: false, openLive: false});
  };
  private readonly uploadInputRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.uploadInputRef = React.createRef();
  }

  public handleUploadInputClick = () => {
    this.uploadInputRef.current?.click();
  };

  public handleVideoButtonClick = () => {
    this.props.changeIsLiveStatus(true);
    if (this.props.isAnalyticRequired) {
      this.props.initImpression("LIVE");
    }
  };

  public handleUploadImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files?.length) {
      return;
    }

    const fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    fileReader.onloadend = () => {
      if (typeof fileReader.result !== "string") {
        return;
      }
      const image = new Image();
      image.src = fileReader.result;
      image.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.setAttribute("id", "canvas_downsize");
        const aspect = window.calculateAspectRatioFit(
          image.width,
          image.height,
          1000,
          1200
        );
        // @ts-ignore
        if (window.navigator.isIPhone) {
          canvas.width = image.height;
          canvas.height = image.width;
          const ctx = canvas.getContext("2d");
          ctx?.clearRect(0, 0, canvas.width, canvas.height);
          ctx?.save();
          ctx?.translate(canvas.width / 2, canvas.height / 2);
          ctx?.rotate(Math.PI / 2);
          ctx?.drawImage(image, -image.width / 2, -image.height / 2);
          ctx?.restore();
          window.hermiteFilterResize(canvas, aspect.height, aspect.width, true);
        } else {
          canvas.width = aspect.width;
          canvas.height = aspect.height;
          const ctx = canvas.getContext("2d");
          ctx?.scale(aspect.width / image.width, aspect.height / image.height);
          ctx?.drawImage(image, 0, 0);
          window.hermiteFilterResize(canvas, aspect.width, aspect.height, true);
        }
        this.props.putCurrentModel({
          value: 0,
          modelImage: canvas.toDataURL("image/jpeg")
        });
        this.props.history.push(ROUTES.tryOn);
      };
      if (this.props.isAnalyticRequired) {
        this.props.initImpression("SELFIE");
      }
    };
  };

  public render() {
    const {
      mainScreenTitle,
      mainScreenDescription,
      mainScreenDisclaimer,
      liveMakeUpButtonText,
      liveMakeUpButtonError,
      uploadButtonText,
      modelButtonText
    } = this.props;
    const { openUpload, openLive, openModel } = this.state;
    return (
      <div className={styles.root}>
        <div>
          <Modal open={openUpload} onClose={this.onCloseModalUpload} center>
              <div className="consent-text">I am age eighteen (18) or older and consent to the processing of my photo and/or image by AlgoFace for the purpose of this demo.
              </div>
              <div className="consent-button">
                  <button className="consent green" onClick={this.handleUploadInputClick}>I Consent</button>
              </div>
          </Modal>
        </div>
        <div>
          <Modal open={openLive} onClose={this.onCloseModalLive} center>
              <div className="consent-text">I am age eighteen (18) or older and consent to the processing of my photo and/or image by AlgoFace for the purpose of this demo.
              </div>
              <div className="consent-button">
                  <Link
                  to={ROUTES.tryOn}
                  className={styles.buttonWrapper}
                  onClick={this.handleVideoButtonClick}
                >
                  <button className="consent green">I Consent</button>
                  </Link>
              </div>
          </Modal>
        </div>
        <div>
          <Modal open={openModel} onClose={this.onCloseModalModel} center>
              <div className="consent-text">I am age eighteen (18) or older and consent to the processing of my photo and/or image by AlgoFace for the purpose of this demo.
              </div>
              <div className="consent-button">
                  <Link to={ROUTES.models} className={styles.buttonWrapper}>
                    <button className="consent green">I Consent</button>
                  </Link>
              </div>
          </Modal>
        </div>
        <div className={styles.topContainer}>
          <div className={styles.contentContainer}>
            <div>
              {!!mainScreenTitle && (
                <div className={styles.title}>
                  <FormattedMessage id={mainScreenTitle} />
                </div>
              )}

              {!!mainScreenDescription && (
                <div className={styles.description}>
                  <FormattedMessage id={mainScreenDescription} />
                </div>
              )}
              <div className={styles.buttonsContainer}>
                {!!liveMakeUpButtonText && (
                  // <Link
                  //   to={ROUTES.tryOn}
                  //   className={styles.buttonWrapper}
                  //   onClick={this.handleVideoButtonClick}
                  // >
                  <div
                  className={styles.buttonWrapper}
                  onClick={this.onOpenModalLive}
                >
                    <button className={styles.button}>
                      <span className={styles.buttonText}>
                        <FormattedMessage id={liveMakeUpButtonText} />
                      </span>
                      <img
                        className={styles.buttonImg}
                        src={VideoIcon}
                        alt="liveMakeUpButton"
                      />
                    </button>
                    {isChrome && isIOS && !!liveMakeUpButtonError && (
                      <div className={styles.blocker}>
                        <FormattedMessage id={liveMakeUpButtonError} />
                      </div>
                    )}
                    </div>
                  // </Link>
                )}
                {!!uploadButtonText && (
                  <div
                    className={styles.buttonWrapper}
                    // onClick={this.handleUploadInputClick}
                    onClick={this.onOpenModalUpload}
                  >
                    <button className={styles.button}>
                      <span className={styles.buttonText}>
                        <FormattedMessage id={uploadButtonText} />
                      </span>
                      <img
                        className={styles.buttonImg}
                        src={UploadIcon}
                        alt="uploadButton"
                        // onClick={this.handleUploadInputClick}
                        onClick={this.onOpenModalUpload}
                      />
                    </button>
                    <input
                      className={styles.uploadInput}
                      type="file"
                      ref={this.uploadInputRef}
                      onChange={this.handleUploadImageChange}
                      accept="image/jpeg, image/png"
                    />
                  </div>
                )}
                {!!modelButtonText && (
                  <div
                  className={styles.buttonWrapper}
                  onClick={this.onOpenModalModel}
                >
                  {/* <Link to={ROUTES.models} className={styles.buttonWrapper}> */}
                    <button className={styles.button}>
                      <span className={styles.buttonText}>
                        <FormattedMessage id={modelButtonText} />
                      </span>
                      <img
                        className={styles.buttonImg}
                        src={ModelIcon}
                        alt="modelButton"
                      />
                    </button>
                  {/* </Link> */}
                  </div>
                )}
              </div>
              {!!mainScreenDisclaimer && (
                <div className={styles.disclaimer}>
                  <FormattedMessage id={mainScreenDisclaimer} />
                </div>
              )}
            </div>
          </div>
          <div className={styles.bgContainer}>
            <img className={styles.mainBG} src={MainBG} alt="main bg" />
          </div>
        </div>
       
      </div>
    );
  }
}

const mapStateToProps = (state: Store) => ({
  isAnalyticRequired: selectIsAnalyticRequired(state),
  liveMakeUpButtonError: selectLiveMakeUpButtonError(state),
  liveMakeUpButtonText: selectLiveMakeUpButtonText(state),
  uploadButtonText: selectUploadButtonText(state),
  modelButtonText: selectModelButtonText(state)
});

const mapDispatchToProps = {
  initImpression,
  putCurrentModel,
  changeIsLiveStatus
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainPageContent)
);
