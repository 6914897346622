import React from "react";
import { connect } from "react-redux";

import MainPageContent from "components/MainPageContent/MainPageContent";

import styles from "./HomePage.module.scss";
import { Store } from "redux";
import {
  selectMainScreenDescription,
  selectMainScreenDisclaimer,
  selectMainScreenTitle
} from "ducks/app";

interface Props {
  mainScreenTitle: string;
  mainScreenDescription: string;
  mainScreenDisclaimer: string;
}

function HomePage(props: Props) {
  return (
    <div className={styles.root}>
      <MainPageContent
        mainScreenTitle={props.mainScreenTitle}
        mainScreenDescription={props.mainScreenDescription}
        mainScreenDisclaimer={props.mainScreenDisclaimer}
      />
    </div>
  );
}

const mapStateToProps = (state: Store) => ({
  mainScreenTitle: selectMainScreenTitle(state),
  mainScreenDescription: selectMainScreenDescription(state),
  mainScreenDisclaimer: selectMainScreenDisclaimer(state)
});

export default connect(mapStateToProps)(HomePage);
