import Model1 from "static/img/models/1.jpg";
import Model2 from "static/img/models/2.jpg";
import Model3 from "static/img/models/3.jpg";
import Model4 from "static/img/models/4.jpg";

export default {
  // Global
  isIntensitySplittersRequired: true,
  isTranslatesRequired: false,
  isAnalyticRequired: false,
  // Header
  homeURL: "https://www.algoface.ai/",
  menu: [
    //if link starts from http react will generate <a> else it will use <Link> from react
    // { link: "/presets?nextPage=/try-on&isVideo=true", text: "Makeup AR-tist" },
    // { link: "https://www.algoface.ai/", text: "Product Catalogue" }
  ],
  burgerBtnResolution: 767, // Value in pixels when ordinary menu will became menu with button
  // Main screen content
  mainScreenTitle: "home.mainScreenTitle",
  liveMakeUpButtonText: "home.liveMakeUpButtonText",
  liveMakeUpButtonError: "home.liveMakeUpButtonError",
  uploadButtonText: "home.uploadButtonText",
  modelButtonText: "home.modelButtonText",
  mainScreenDescription: `home.mainScreenDescription`,
  mainScreenDisclaimer: "",
  // Footer
  isAlgoFaceBrandingRequired: false,
  //Model selection
  modelScreenTitle: "model.modelScreenTitle",
  backButton: "model.backButton",
  models: [
    { modelImage: Model1, value: 1 },
    { modelImage: Model2, value: 2 },
    { modelImage: Model3, value: 3 },
    { modelImage: Model4, value: 4 }
  ],
  // Canvas controls
  canvasButtonTitleRemove: "canvasControls.remove",
  canvasButtonTitleZoomIn: "canvasControls.zoomIn",
  canvasButtonTitleZoomOut: "canvasControls.zoomOut",
  canvasButtonTitleSplitter: "canvasControls.splitter",
  canvasButtonTitleDownload: "canvasControls.download",
  canvasButtonTitleCamera: "canvasControls.camera",
  canvasSplitterTitle: "canvasControls.splitterTitle",
  canvasSplitterLow: "canvasControls.low",
  canvasSplitterHigh: "canvasControls.high",
  accordionsProducts: "accordions.products",
  accordionsStatement: "accordions.statement",
  accordionsLooks: "accordions.looks",
  accordionsTitle: "accordions.title"
};
