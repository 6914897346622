import { Store } from "redux";
import { createSelector } from "reselect";

import { appName } from "utils/constants";
import {
  ActionType,
  MakeUpEntitiesType,
  MakeUpDataType,
  FullLooksType,
  FullLooksDataType
} from "types/common";
import makeUpConstants from "utils/customerConstants/makeUpConstants";
import {
  eyeShadowsLooks,
  fullLooks
} from "utils/customerConstants/looksConstants";

/* Constants */
export const moduleName = "makeUp";
const prefix = `${appName}/${moduleName}`;

export const PUT_MAKEUP_STATUS = `${prefix}/PUT_MAKEUP_STATUS`;
export const PUT_MAKEUP_OPACITY = `${prefix}/PUT_MAKEUP_OPACITY`;
export const PUT_EYE_SHADOW_LOOKS_OPACITY = `${prefix}/PUT_EYE_SHADOW_LOOKS_OPACITY`;
export const PUT_FULL_LOOK_STATUS = `${prefix}/PUT_FULL_LOOK_STATUS`;
export const PUT_EYE_SHADOW_LOOK_STATUS = `${prefix}/PUT_EYE_SHADOW_LOOK_STATUS`;
export const RESET_MAKEUP_STATUS = `${prefix}/RESET_MAKEUP_STATUS`;

/* Reducer */
interface State {
  entities: MakeUpEntitiesType;
  eyeShadowsLooks: FullLooksType;
  fullLooks: FullLooksType;
}

const initialState: State = {
  entities: makeUpConstants,
  eyeShadowsLooks,
  fullLooks
};

export default function reducer(
  state: State = initialState,
  action: ActionType
) {
  const { type, payload } = action;

  switch (type) {
    case PUT_MAKEUP_STATUS:
    case PUT_MAKEUP_OPACITY: {
      const { type, makeUp } = payload;
      const { entities } = state;
      const newPalette = entities[type].map(previousMakeUp =>
        previousMakeUp.idx === makeUp.idx &&
        previousMakeUp.sku_id === makeUp.sku_id &&
        previousMakeUp.sku_name === makeUp.sku_name
          ? makeUp
          : previousMakeUp
      );

      const result = Object.assign({}, state, {
        entities: {
          ...entities,
          [type]: newPalette
        }
      });
      return result;
    }
    case PUT_EYE_SHADOW_LOOK_STATUS: {
      const { name } = payload;
      const { eyeShadowsLooks } = state;

      const result = Object.assign({}, state, {
        eyeShadowsLooks: {
          ...eyeShadowsLooks,
          [name]: {
            ...eyeShadowsLooks[name],
            isActive: !eyeShadowsLooks[name].isActive,
            entities: eyeShadowsLooks[name].entities.map(item => {
              return {
                ...item,
                a: item.defaultOpacity
              };
            })
          }
        }
      });
      return result;
    }
    case PUT_EYE_SHADOW_LOOKS_OPACITY: {
      const { name, entities } = payload;
      const { eyeShadowsLooks } = state;

      const result = Object.assign({}, state, {
        eyeShadowsLooks: {
          ...eyeShadowsLooks,
          [name]: {
            ...eyeShadowsLooks[name],
            isActive: !eyeShadowsLooks[name].isActive,
            entities
          }
        }
      });
      return result;
    }
    case PUT_FULL_LOOK_STATUS: {
      const { name } = payload;
      const { fullLooks } = state;

      const result = Object.assign({}, state, {
        fullLooks: {
          ...fullLooks,
          [name]: {
            ...fullLooks[name],
            isActive: !fullLooks[name].isActive
          }
        }
      });
      return result;
    }
    case RESET_MAKEUP_STATUS:
      return initialState;
    default:
      return state;
  }
}

/*Selectors */
const stateSelector = (state: Store) => state[moduleName];

export const selectMakeUpData = createSelector(
  stateSelector,
  (state: State) => state.entities
);

export const selectEyeShadowsLooksData = createSelector(
  stateSelector,
  (state: State) => state.eyeShadowsLooks
);

export const selectFullLooksData = createSelector(
  stateSelector,
  (state: State) => state.fullLooks
);

export const selectActiveEyeShadowLookName = createSelector(
  stateSelector,
  (state: State) => {
    let nameOfActiveLook = "";
    const looks = state.eyeShadowsLooks;
    for (const name in looks) {
      if (looks[name].isActive) {
        nameOfActiveLook = name;
      }
    }
    return nameOfActiveLook;
  }
);

export const selectActiveFullLookName = createSelector(
  stateSelector,
  (state: State) => {
    let nameOfActiveLook = "";
    const looks = state.fullLooks;
    for (const name in looks) {
      if (looks[name].isActive) {
        nameOfActiveLook = name;
      }
    }
    return nameOfActiveLook;
  }
);

/* Action Creators */

export function putMakeUpStatus(type: string, makeUp: MakeUpDataType) {
  return {
    type: PUT_MAKEUP_STATUS,
    payload: {
      type,
      makeUp
    }
  };
}

export function putMakeUpOpacity(type: string, makeUp: MakeUpDataType) {
  return {
    type: PUT_MAKEUP_OPACITY,
    payload: {
      type,
      makeUp
    }
  };
}

export function putEyeShadowLookOpacityStatus(
  name: string,
  entities: FullLooksDataType[]
) {
  return {
    type: PUT_EYE_SHADOW_LOOKS_OPACITY,
    payload: {
      name,
      entities
    }
  };
}

export function putEyeShadowLooksStatus(name: string) {
  return {
    type: PUT_EYE_SHADOW_LOOK_STATUS,
    payload: {
      name
    }
  };
}

export function putFullLooksStatus(name: string) {
  return {
    type: PUT_FULL_LOOK_STATUS,
    payload: {
      name
    }
  };
}

export function resetMakeUpStatus() {
  return {
    type: RESET_MAKEUP_STATUS
  };
}
