import React from "react";
import { connect } from "react-redux";
import { Icon } from "semantic-ui-react";
import { Store } from "redux";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

import { ActionType, FullLooksType, FullLooksDataType } from "types/common";
import { MaskData } from "utils/constants";
import {
  putEyeShadowLookOpacityStatus,
  putEyeShadowLooksStatus,
  selectActiveEyeShadowLookName
} from "ducks/makeUp";
import InputRange from "components/InputRange/InputRange";
import {
  selectAccordionsTitle,
  selectIsIntensitySplittersRequired
} from "ducks/app";

import styles from "components/AccordionsFullLooks/AccordionsFullLooks.module.scss";

interface Props {
  name: string;
  icon: string;
  accordionsTitle: string;
  isIntensitySplittersRequired: boolean;
  activeFullLookName: string;
  fullLooks: FullLooksType;
  openedAccordionType: string | null;
  onAccordionClick: (type: string) => () => void;
  putEyeShadowLooksStatus: (name: string) => ActionType;
  putEyeShadowLookOpacityStatus: (
    name: string,
    entities: FullLooksDataType[]
  ) => ActionType;
}

function AccordionsFullLooks(props: Props) {
  const { openedAccordionType, activeFullLookName, fullLooks } = props;

  const onResetClick = () => {
    for (const look in fullLooks) {
      if (fullLooks[look].isActive) {
        fullLooks[look].entities.forEach(look => {
          const maskType = MaskData[look.type];
          const maskTypeName = maskType.func.replace("apply", "remove");
          window.SERVICES.vmtcore[maskTypeName]();
        });
        props.putEyeShadowLooksStatus(look);
      }
    }
  };

  const onSliderChange = (value: number) => {
    const multiplier = value / 100;
    const activeLookEntities = fullLooks[activeFullLookName]?.entities;
    onResetClick();

    const entities: FullLooksDataType[] = [];

    activeLookEntities.forEach(mask => {
      const makeUp = Object.assign(
        {},
        {
          ...mask,
          a: mask.defaultOpacity * multiplier
        }
      );
      entities.push(makeUp);

      const maskType = MaskData[mask.type];
      const maskArguments = maskType.arguments.map(
        (arg: string) => makeUp[arg] || null
      );

      window.SERVICES.vmtcore[maskType.func](...maskArguments);
    });
    props.putEyeShadowLookOpacityStatus(activeFullLookName, entities);
  };

  const onLookClick = (
    lookData: {
      isActive: boolean;
      entities: FullLooksDataType[];
    },
    lookName: string
  ) => () => {
    if (!lookData.isActive) {
      onResetClick();
      lookData.entities.forEach(mask => {
        const maskType = MaskData[mask.type];
        const maskArguments = maskType.arguments.map(
          (arg: string) => mask[arg] || null
        );
        window.SERVICES.vmtcore[maskType.func](...maskArguments);
      });
      props.putEyeShadowLooksStatus(lookName);
    }
  };

  const generateMultiColor = (items: FullLooksDataType[]) => {
    const parts = items.length;
    const percentPerPart = 100 / parts;
    const rgbaList = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      rgbaList.push(
        `rgba(${item.r}, ${item.g}, ${item.b}, ${item.a}) ${percentPerPart *
          i}% ${percentPerPart * (i + 1)}%`
      );
    }
    return `linear-gradient(75deg,${rgbaList.join(",")})`;
  };

  const renderLooks = () => {
    const looks = [];
    for (const look in fullLooks) {
      let image = fullLooks[look].image;
      if (!image) {
        image = generateMultiColor(fullLooks[look].entities);
      } else {
        image = `url(${image})`;
      }

      looks.push(
        <div
          key={look}
          className={classNames(styles.colorItem, {
            [styles.active]: fullLooks[look].isActive
          })}
          onClick={onLookClick(fullLooks[look], look)}
          style={{
            background: image,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat"
          }}
        />
      );
    }

    looks.unshift(
      <div key={0} className={styles.colorItem} onClick={onResetClick} />
    );
    return looks;
  };

  const activeLookEntity = fullLooks[activeFullLookName]?.entities[0];

  return (
    <>
      <div className={styles.typeContainer}>
        <div
          className={styles.typeTitleContainer}
          onClick={props.onAccordionClick("LOOKS")}
        >
          <div className={styles.typeTitle}>
            <img className={styles.typeIcon} src={props.icon} alt="icon" />
            <span className={styles.text}>
              <FormattedMessage id={props.accordionsTitle} />{" "}
              <FormattedMessage id={MaskData[props.name.toUpperCase()].name} />
            </span>
          </div>
          <Icon
            name="dropdown"
            size="small"
            flipped={openedAccordionType === "LOOKS" ? "vertically" : undefined}
          />
        </div>
        {openedAccordionType === "LOOKS" && (
          <div className={styles.colorsContainer}>{renderLooks()}</div>
        )}
        {props.isIntensitySplittersRequired && (
          <div
            className={classNames(styles.rangeWrapper, {
              [styles.active]:
                openedAccordionType === "LOOKS" && !!activeFullLookName
            })}
          >
            <InputRange
              defaultValue={100}
              resetSlider={
                !!activeFullLookName &&
                activeLookEntity.a === activeLookEntity.defaultOpacity
              }
              maxValue={125}
              minValue={75}
              step={1}
              onChange={onSliderChange}
            />
          </div>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state: Store) => ({
  activeFullLookName: selectActiveEyeShadowLookName(state),
  accordionsTitle: selectAccordionsTitle(state),
  isIntensitySplittersRequired: selectIsIntensitySplittersRequired(state)
});

const mapDispatchToProps = {
  putEyeShadowLooksStatus,
  putEyeShadowLookOpacityStatus
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccordionsFullLooks);
