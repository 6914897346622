if (!window.SERVICES) {
  window.SERVICES = {};
}

class VideoDriver {
  constructor() {
    this.isSafari = navigator.sayswho.toLowerCase().indexOf("safari") != -1;
    this.mediaStream = null;
    this.liveStream = null;
    this.videoElement = null;
    this.canvasElementContext = null;
    this.activeDeviceId = 0;
    this.cb = null;
    this.initCb = null;
    this.initErrCb = null;
    this.cameraCount = 0;
    this.exclusionList = ["Microsoft IR Camera Front"];
  }

  init(videoId, canvasId) {
    console.log(
      navigator.sayswho,
      " browser recognized, initalizing camera parameters ..."
    );
    //get video element
    this.videoElement = document.getElementById(videoId);
    // video.setAttribute('autoplay', '');
    // video.setAttribute('muted', '');
    // video.setAttribute('playsinline', '');
    //get canvas element and context
    this.canvasElement = document.getElementById(canvasId);
    this.canvasElementContext = this.canvasElement.getContext("2d");
  }

  start(cb, errCb) {
    this.initCb = cb;
    this.initErrCb = errCb;
    return this.toggleCamera();
  }

  stopMediaTracks(stream) {
    this.videoElement.pause();
    stream.getTracks().forEach(track => track.stop());
  }

  async toggleCamera(changeDevice) {
    try {
      var self = this;
      // query existing video input devices except active device
      var activeDevices = await self.getActiveDevices();

      //update camera count
      self.cameraCount = activeDevices.length;
      // check if a device is available
      if (activeDevices.length < 1) {
        return "Camera may be disabled. Please enable the camera access for this web page to use our app";
      }

      // get a device and update instance state
      if (changeDevice) {
        self.activeDeviceId += 1;
      }

      if (
        self.activeDeviceId < 0 ||
        self.activeDeviceId >= activeDevices.length
      ) {
        self.activeDeviceId = 0;
      }
      var myDevice = activeDevices[self.activeDeviceId];

      console.log(`[INFO] Using ${myDevice.label}`);

      // kill current media tracks
      if (self.liveStream != null) {
        self.stopMediaTracks(self.liveStream);
      }

      if (myDevice.deviceId === "") {
        self.mediaStream = window.navigator.mediaDevices.getUserMedia({
          audio: false,
          video: true
        });
      } else {
        // get new media stream
        self.mediaStream = window.navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            deviceId: {
              exact: myDevice.deviceId
            }
          }
        });
      }

      // act on media stream
      const error = await self.mediaStream
        .then(stream => {
          // SAFARI
          if (self.isSafari) {
            self.videoElement.setAttribute(
              "webkit-playsinline",
              "webkit-playsinline"
            );
            self.videoElement.setAttribute("playsinline", "true");
          }
          self.liveStream = stream;
          self.videoElement.srcObject = self.liveStream;
          self.videoElement.onplay = self.onPlay.bind(self);
          self.videoElement.play();
        })
        .catch(err => {
          setTimeout(self.initErrCb, 5000, err);
          return err.message;
        });

      if (!!error) {
        throw error;
      }
    } catch (error) {
      return error;
    }
  }

  getActiveDevices() {
    var self = this;
    return navigator.mediaDevices
      .enumerateDevices()
      .then(devices => {
        return devices.filter(
          d =>
            d.kind === "videoinput" && self.exclusionList.indexOf(d.label) == -1
        );
      })
      .catch(err => console.warn(err));
  }

  adjustSize() {
    //adjusting canvas sizes
    this.width = this.videoElement.videoWidth;
    this.height = this.videoElement.videoHeight;
    this.canvasElement.width = this.width;
    this.canvasElement.height = this.height;
    this.canvasElementContext.translate(this.width, 0);
    this.canvasElementContext.scale(-1, 1);
  }

  registerDrawCb(cb) {
    this.cb = cb;
  }

  draw() {
    var self = this;
    if (
      self.canvasElementContext &&
      self.videoElement &&
      !self.videoElement.paused &&
      !self.videoElement.ended
    ) {
      self.canvasElementContext.drawImage(
        self.videoElement,
        0,
        0,
        self.width,
        self.height
      );
      self.videoElement.addEventListener("canplaythrough", function() {
        if (
          self.canvasElementContext &&
          self.videoElement &&
          !self.videoElement.paused &&
          !self.videoElement.ended
        ) {
          self.canvasElementContext.drawImage(
            self.videoElement,
            0,
            0,
            self.width,
            self.height
          );
        }
      });
      if (self.cb) self.cb();
      requestAnimationFrame(self.draw.bind(self));
      // requestAnimationFrame(()=>{self.draw()});
      // setTimeout(()=>{self.draw()},30)
    }
  }

  onPlay() {
    var self = this;
    self.interval = setInterval(() => {
      if (
        self.videoElement.videoHeight > 0 &&
        self.videoElement.videoWidth > 0
      ) {
        console.log(`[STREAM] streaming started.`);
        clearInterval(self.interval);
        self.initCb();
      }
    }, 20);
  }

  play() {
    this.videoElement.play();
  }

  pause() {
    this.videoElement.pause();
    this.videoElement.src = "";
    if (this.liveStream) this.liveStream.getVideoTracks()[0].stop();
  }
}

window.SERVICES.videoClass = VideoDriver;
window.SERVICES.video = new VideoDriver();
