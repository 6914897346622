import Foundation from "static/img/icons/foundation.png";
import Blush from "static/img/icons/blush.png";
import Highlighter from "static/img/icons/highlighter.png";
import Contour from "static/img/icons/contour.png";
import Bronzer from "static/img/icons/bronzer.png";
import Lipstick from "static/img/icons/lipstick.png";
import Lipliner from "static/img/icons/lipliner.png";
import Eyeshadow from "static/img/icons/eyeshadow.png";
import Eyelash from "static/img/icons/eyelash.png";
import Eyeliner from "static/img/icons/eyeliner.png";
import Eyebrow from "static/img/icons/eyebrow.png";

export const appName = "algoface-web-app";
export const organisation = process.env.REACT_APP_SUA_ORGANISATION || "";
export const product = process.env.REACT_APP_SUA_PRODUCT || "";
export const analyticServerURL = process.env.REACT_APP_SUA_SERVER || "";

export const ROUTES = {
  root: "/",
  tutorial: "/tutorial",
  videoGuide: "/video",
  presets: "/presets",
  tryOn: "/try-on",
  models: "/models"
};

export const MAX_ZOOM = 5;

export const MaskData = {
  EYESHADOW: {
    func: "applyEyeshadow",
    name: "makeup.eyeshadow",
    arguments: ["mask", "r", "g", "b", "a", "shimmer_mask", "shimmer_intensity"]
  },
  FOUNDATION: {
    func: "applyFoundation",
    name: "makeup.foundation",
    arguments: ["r", "g", "b", "a", "mask"]
  },
  CONTOUR: {
    func: "applyContour",
    name: "makeup.contour",
    arguments: ["mask", "r", "g", "b", "a"]
  },
  BRONZER: {
    func: "applyBronzer",
    name: "makeup.bronzer",
    arguments: ["mask", "r", "g", "b", "a"]
  },
  BLUSH: {
    func: "applyBlush",
    name: "makeup.blush",
    arguments: ["mask", "r", "g", "b", "a", "shimmer_mask", "shimmer_intensity"]
  },
  BLENDER: {
    func: "applyBlender",
    name: "makeup.blender",
    arguments: ["r", "g", "b", "a"]
  },
  HIGHLIGHTER: {
    func: "applyHighlighter",
    name: "makeup.highlighter",
    arguments: ["mask", "r", "g", "b", "a", "shimmer_mask", "shimmer_intensity"]
  },
  EYELASH: {
    func: "applyEyelash",
    name: "makeup.eyelash",
    arguments: ["mask", "r", "g", "b", "a"]
  },
  EYELINER: {
    func: "applyEyeliner",
    name: "makeup.eyeliner",
    arguments: ["mask", "r", "g", "b", "a"]
  },
  EYEBROW: {
    func: "applyEyebrow",
    name: "makeup.eyebrow",
    arguments: ["mask", "r", "g", "b", "a"]
  },
  LIPSTICK: {
    func: "applyLipstick",
    name: "makeup.lipstick",
    arguments: [
      "r",
      "g",
      "b",
      "a",
      "finish_intensity",
      "finish_type",
      "glitter_mask",
      "glitter_intensity",
      "shimmer_mask",
      "shimmer_intensity"
    ]
  },
  LIPLINER: {
    func: "applyLipliner",
    name: "makeup.lipliner",
    arguments: ["mask", "r", "g", "b", "a"]
  }
};

export const makeUpIcons = {
  foundation: Foundation,
  blush: Blush,
  highlighter: Highlighter,
  contour: Contour,
  bronzer: Bronzer,
  lipstick: Lipstick,
  lipliner: Lipliner,
  eyeshadow: Eyeshadow,
  eyelash: Eyelash,
  eyeliner: Eyeliner,
  eyebrow: Eyebrow
};
