import { combineReducers } from "redux";
import presetsModule, { moduleName as presetsModuleName } from "ducks/app";
import makeUpModule, { moduleName as makeUpModuleName } from "ducks/makeUp";
import analyticsModule, {
  moduleName as analyticsModuleName
} from "ducks/analytics";

// Add all reducers here.
export default combineReducers({
  [makeUpModuleName]: makeUpModule,
  [presetsModuleName]: presetsModule,
  [analyticsModuleName]: analyticsModule
});
