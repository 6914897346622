if (!window.SERVICES) {
  window.SERVICES = {};
}

class ImageDriver {
  constructor() {
    this.canvasElement = null;
    this.canvasElementContext = null;
    this.img = null;
    this.cb = null;
    this.width = null;
    this.height = null;
  }

  registerDrawCb(cb) {
    this.cb = cb;
  }

  init(canvasId, imageURI, constrained = false) {
    this.canvasElement = document.getElementById(canvasId);
    this.canvasElementContext = this.canvasElement.getContext("2d");
    this.img = new Image();
    var self = this;
    this.img.onload = function() {
      //adjusting canvas sizes
      self.width = self.img.width;
      self.height = self.img.height;
      self.canvasElement.width = self.width;
      self.canvasElement.height = self.height;
      self.canvasElementContext.drawImage(
        self.img,
        0,
        0,
        self.width,
        self.height,
        0,
        0,
        self.width,
        self.height
      );

      if (constrained) {
        let aspect = window.calculateAspectRatioFit(
          self.width,
          self.height,
          1000,
          1200
        );
        window.hermiteFilterResize(
          self.canvasElement,
          aspect.width,
          aspect.height,
          true
        );
      }

      if (self.cb) self.cb();
    };
    this.img.src = imageURI;
  }
}

window.SERVICES.imageClass = ImageDriver;
window.SERVICES.image = new ImageDriver();
