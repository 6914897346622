import React from "react";
import { Icon } from "semantic-ui-react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Store } from "redux";
import { FormattedMessage } from "react-intl";

import { ActionType, MakeUpDataType, MakeUpEntitiesType } from "types/common";
import { makeUpIcons, MaskData } from "utils/constants";
import InputRange from "components/InputRange/InputRange";
import { putMakeUpOpacity } from "ducks/makeUp";
import {
  selectAccordionsTitle,
  selectIsAnalyticRequired,
  selectIsIntensitySplittersRequired
} from "ducks/app";
import { tryoutEvent } from "utils/helpers";

import styles from "./Accordions.module.scss";

interface Props {
  isAnalyticRequired: boolean;
  isIntensitySplittersRequired: boolean;
  accordionsTitle: string;
  onAccordionClick: (type: string) => () => void;
  putMakeUpStatus: (type: string, makeUp: MakeUpDataType) => ActionType;
  putMakeUpOpacity: (type: string, makeUp: MakeUpDataType) => ActionType;
  currentMakeUp: MakeUpEntitiesType;
  openedAccordionType: string | null;
}

function Accordions(props: Props) {
  const { currentMakeUp, putMakeUpStatus, putMakeUpOpacity } = props;
  const AccordionsLayout = [];

  const onResetClick = (type: string) => () => {
    const { currentMakeUp } = props;
    currentMakeUp[type].forEach(makeUp => {
      if (makeUp.isActive) {
        const maskType = MaskData[makeUp.type];
        const maskTypeName = maskType.func.replace("apply", "remove");
        window.SERVICES.vmtcore[maskTypeName]();
        putMakeUpStatus(type, {
          ...makeUp,
          isActive: false,
          a: makeUp.defaultOpacity
        });
      }
    });
  };

  const onSliderChange = (type: string) => (value: number) => {
    const { currentMakeUp } = props;
    const multiplier = value / 100;

    const makeUpByType = currentMakeUp[type].filter(item => item.isActive);

    onResetClick(type)();

    makeUpByType.forEach(item => {
      const makeUp = Object.assign(
        {},
        {
          ...item,
          a: item.defaultOpacity * multiplier
        }
      );
      const maskType = MaskData[makeUp.type];
      const maskArguments = maskType.arguments.map(
        (arg: string) => makeUp[arg] || null
      );
      window.SERVICES.vmtcore[maskType.func](...maskArguments);
      putMakeUpOpacity(type, makeUp);
    });
  };

  const onColorClick = (makeUp: MakeUpDataType, sectionType: string) => () => {
    if (!makeUp.isActive) {
      const maskType = MaskData[makeUp.type];
      const maskArguments = maskType.arguments.map(
        (arg: string) => makeUp[arg] || null
      );

      onResetClick(sectionType)();
      window.SERVICES.vmtcore[maskType.func](...maskArguments);
      if (props.isAnalyticRequired) {
        tryoutEvent(makeUp);
      }
      putMakeUpStatus(sectionType, {
        ...makeUp,
        isActive: true
      });
    }
  };

  const renderColors = (colors: MakeUpDataType[], sectionType: string) => {
    const typeColors = colors.map(color => {
      return (
        <div
          key={color.idx + color.sku_id}
          className={classNames(styles.colorItem, {
            [styles.active]: color.isActive
          })}
          onClick={onColorClick(color, sectionType)}
          style={{
            background: `rgb(${color.r}, ${color.g}, ${color.b})`
          }}
        />
      );
    });
    typeColors.unshift(
      <div
        key={0}
        className={styles.colorItem}
        onClick={onResetClick(sectionType)}
      />
    );
    return typeColors;
  };

  for (const type in currentMakeUp) {
    const activeItem = props.currentMakeUp[type].find(item => item.isActive);
    AccordionsLayout.push(
      <div className={styles.typeContainer} key={type}>
        <div
          className={styles.typeTitleContainer}
          onClick={props.onAccordionClick(type)}
        >
          <div className={styles.typeTitle}>
            <img
              className={styles.typeIcon}
              src={makeUpIcons[type]}
              alt="icon"
            />
            <span className={styles.text}>
              <FormattedMessage id={props.accordionsTitle} />{" "}
              <FormattedMessage id={MaskData[type.toUpperCase()].name} />
            </span>
          </div>
          <Icon
            name="dropdown"
            size="small"
            flipped={
              props.openedAccordionType === type ? "vertically" : undefined
            }
          />
        </div>
        {props.openedAccordionType === type && (
          <div className={styles.colorsContainer}>
            {renderColors(currentMakeUp[type], type)}
          </div>
        )}
        {props.isIntensitySplittersRequired && (
          <div
            className={classNames(styles.rangeWrapper, {
              [styles.active]:
                props.openedAccordionType === type && !!activeItem
            })}
          >
            <InputRange
              defaultValue={100}
              resetSlider={
                !!activeItem && activeItem?.a === activeItem?.defaultOpacity
              }
              maxValue={125}
              minValue={75}
              step={1}
              onChange={onSliderChange(type)}
            />
          </div>
        )}
      </div>
    );
  }

  return <>{AccordionsLayout}</>;
}

const mapStateToProps = (state: Store) => ({
  isIntensitySplittersRequired: selectIsIntensitySplittersRequired(state),
  accordionsTitle: selectAccordionsTitle(state),
  isAnalyticRequired: selectIsAnalyticRequired(state)
});

const mapDispatchToProps = {
  putMakeUpOpacity
};

export default connect(mapStateToProps, mapDispatchToProps)(Accordions);
