import React, { Component } from "react";
import { Store } from "redux";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Dropdown, DropdownProps, Icon } from "semantic-ui-react";

import Logo from "static/img/logo.png";
import {
  selectBurgerBtnResolution,
  selectHomeURL,
  selectIsTranslatesRequired,
  selectLanguage,
  selectMenuItems,
  setLanguage
} from "ducks/app";
import { ActionType, LinkDataType } from "types/common";

import styles from "./Header.module.scss";
import { ROUTES } from "utils/constants";

const langOptions = [
  { value: "en", text: "EN" },
  { value: "ru", text: "RU" }
];

interface Props {
  lang: string;
  homeURL: string;
  isTranslatesRequired: boolean;
  menu: LinkDataType[];
  burgerBtnResolution: number;
  setLanguage: (payload: string) => ActionType;
}

interface State {
  width: number;
  isMenuActive: boolean;
}

class Header extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      width: window.innerWidth,
      isMenuActive: false
    };
  }

  public componentDidMount() {
    window.addEventListener("resize", this.onWidthChange);
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.onWidthChange);
  }

  public onWidthChange = () => {
    this.setState({ width: window.innerWidth });
  };

  public onMenuButtonClick = () => {
    this.setState({ isMenuActive: !this.state.isMenuActive });
  };

  public onLangChange = (
    event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => {
    this.props.setLanguage(data.value as string);
  };

  public renderMenu = () => {
    const menu = this.props.menu.map(item => {
      if (item.link.indexOf("http") !== -1) {
        return (
          <a href={item.link} className={styles.menuItem} key={item.link}>
            <span>{item.text}</span>
          </a>
        );
      } else {
        return (
          <NavLink
            to={item.link}
            className={styles.menuItem}
            activeClassName={styles.active}
            key={item.link}
          >
            <span>{item.text}</span>
          </NavLink>
        );
      }
    });
    if (this.props.isTranslatesRequired) {
      menu.push(
        <Dropdown
          key="lang"
          fluid={true}
          className={styles.menuItem}
          options={langOptions}
          value={this.props.lang}
          onChange={this.onLangChange}
        />
      );
    }

    return menu;
  };

  public render() {
    return (
      <div className={styles.root}>
        <a
          href={this.props.homeURL}
          className={styles.logoContainer}
          target="_blank"
          rel="noreferrer noopener"
        >
          <img className={styles.logo} src={Logo} alt="logo" />
        </a>
        {this.state.width > this.props.burgerBtnResolution ? (
          <nav className={styles.menu}>{this.renderMenu()}</nav>
        ) : (
          <Icon
            name="bars"
            className={styles.icon}
            onClick={this.onMenuButtonClick}
          />
        )}
        {this.state.isMenuActive &&
          this.state.width < this.props.burgerBtnResolution && (
            <nav className={styles.mobileMenu}>{this.renderMenu()}</nav>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state: Store) => ({
  homeURL: selectHomeURL(state),
  burgerBtnResolution: selectBurgerBtnResolution(state),
  menu: selectMenuItems(state),
  isTranslatesRequired: selectIsTranslatesRequired(state),
  lang: selectLanguage(state)
});

const mapDispatchToProps = {
  setLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
