import React from 'react'
import ReactDOM from 'react-dom'
import styles from "./CTABanner.module.scss";

class CTABanner extends React.Component {


    render() {
        return <div className={styles.mianContainerCTA}>
            <div className={styles.mainTitle}>Ready to experience the power of Virtual Makeup?</div>
            <div className={styles.contactUsTitle}>Contact us today for a free makeup AR rendering of your product.</div>
            <div className={styles.getStartedContainer}>
                <a className={styles.anchorGetStarted} href="https://www.algoface.ai/virtual-makeup-tryon-request/?source=showroom" target="_blank">Get Started</a>
            </div>
        </div>
    }
}

export default CTABanner;