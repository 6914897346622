import React from "react";

import ModelPageContent from "components/ModelPageContent/ModelPageContent";

import styles from "./ModelsPage.module.scss";

export default function ModelsPage() {
  return (
    <div className={styles.root}>
      <ModelPageContent />
    </div>
  );
}
