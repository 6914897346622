if (!window.SERVICES) {
  window.SERVICES = {};
}

class VmtLog {
  constructor() {
    this.default = "";
    this.currentInfo = "";
    this.currentErr = "";
  }

  registerInfo(text) {
    this.currentInfo = text;
  }

  registerErr(text) {
    this.currentErr = text;
  }

  getInfo() {
    return this.currentInfo;
  }

  getErr() {
    return this.currentErr;
  }

  clear() {
    this.currentErr = this.default;
    this.currentInfo = this.default;
  }
}

window.SERVICES.vmtlogClass = VmtLog;
window.SERVICES.vmtlog = new VmtLog();
