import React from "react";
import { Store } from "redux";
import { connect } from "react-redux";
import classNames from "classnames";

import { ActionType, FullLooksDataType, FullLooksType } from "types/common";
import { MaskData } from "utils/constants";
import {
  putFullLooksStatus,
  selectActiveFullLookName,
  selectFullLooksData
} from "ducks/makeUp";

import styles from "./LooksSelector.module.scss";

interface Props {
  activeFullLookName: string;
  fullLooks: FullLooksType;
  putFullLooksStatus: (name: string) => ActionType;
}

function LooksSelector(props: Props) {
  const onResetClick = () => {
    const { fullLooks } = props;
    for (const look in fullLooks) {
      if (fullLooks[look].isActive) {
        fullLooks[look].entities.forEach(look => {
          const maskType = MaskData[look.type];
          const maskTypeName = maskType.func.replace("apply", "remove");
          window.SERVICES.vmtcore[maskTypeName]();
        });
        props.putFullLooksStatus(look);
      }
    }
  };

  const onLookClick = (
    lookData: {
      isActive: boolean;
      entities: FullLooksDataType[];
    },
    lookName: string
  ) => () => {
    if (!lookData.isActive) {
      onResetClick();
      lookData.entities.forEach(mask => {
        const maskType = MaskData[mask.type];
        const maskArguments = maskType.arguments.map(
          (arg: string) => mask[arg] || null
        );
        window.SERVICES.vmtcore[maskType.func](...maskArguments);
      });
      props.putFullLooksStatus(lookName);
    }
  };

  const renderLooks = () => {
    const { fullLooks } = props;
    const looks = [];
    for (const look in fullLooks) {
      looks.push(
        <img
          key={look}
          className={classNames(styles.modelItem, {
            [styles.active]: fullLooks[look].isActive
          })}
          onClick={onLookClick(fullLooks[look], look)}
          src={fullLooks[look].image || ""}
          alt=""
        />
      );
    }
    return looks;
  };

  return (
    <>
      <div className={styles.typeContainer}>
        <div className={styles.wrapper}>{renderLooks()}</div>
      </div>
    </>
  );
}

const mapStateToProps = (state: Store) => ({
  fullLooks: selectFullLooksData(state),
  activeFullLookName: selectActiveFullLookName(state)
});

const mapDispatchToProps = {
  putFullLooksStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(LooksSelector);
