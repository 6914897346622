import { Store } from "redux";
import { createSelector } from "reselect";
import { SagaIterator } from "redux-saga";
import { select, put, all, takeEvery } from "redux-saga/effects";

import { appName, MAX_ZOOM } from "utils/constants";
import customerConstants from "utils/customerConstants/customerConstants";
import {
  ActionType,
  ModelDataType,
  CustomerConstantsType,
  ErrorType
} from "types/common";
import { resetMakeUpStatus } from "ducks/makeUp";

/* Constants */

export const moduleName = "app";
const prefix = `${appName}/${moduleName}`;

export const SET_LANGUAGE = `${prefix}/SET_LANGUAGE`;
export const INIT_CANVAS = `${prefix}/INIT_CANVAS`;
export const SET_CANVAS_ERROR = `${prefix}/SET_CANVAS_ERROR`;
export const PUT_MODEL_PRESET = `${prefix}/PUT_MODEL_PRESET`;
export const CHANGE_VMT_STATUS = `${prefix}/CHANGE_VMT_STATUS`;
export const CHANGE_IS_LIVE_STATUS = `${prefix}/CHANGE_IS_LIVE_STATUS`;
export const CHECK_MULTI_CAMERA_STATUS = `${prefix}/CHECK_MULTI_CAMERA_STATUS`;
export const CHANGE_IS_MULTI_CAMERA_STATUS = `${prefix}/CHANGE_IS_MULTI_CAMERA_STATUS`;
export const TOGGLE_MULTI_CAMERA = `${prefix}/toggleSplitter`;
export const CHANGE_ZOOM_VALUE = `${prefix}/CHANGE_ZOOM_VALUE`;
export const SET_ZOOM_VALUE = `${prefix}/SET_ZOOM_VALUE`;
export const SET_INTENSITY_VALUE = `${prefix}/SET_INTENSITY_VALUE`;
export const SET_SPLITTER_VALUE = `${prefix}/SET_SPLITTER_VALUE`;
export const DOWNLOAD_IMAGE = `${prefix}/DOWNLOAD_IMAGE`;
export const RESET_CANVAS_VIEW = `${prefix}/RESET_CANVAS_VIEW`;

/* Reducer */

interface State {
  currentModel: ModelDataType | null;
  lang: string;
  isVmtReady: boolean;
  isLive: boolean;
  isMultiCamera: boolean;
  zoomValue: number;
  isSplitterActive: boolean;
  intensityLevel: number;
  customerConstants: CustomerConstantsType;
  error: ErrorType | null;
}

const initialState: State = {
  currentModel: null,
  lang: "en",
  isVmtReady: false,
  isLive: false,
  isMultiCamera: false,
  isSplitterActive: false,
  zoomValue: 0,
  intensityLevel: 75,
  customerConstants,
  error: null
};

export default function reducer(
  state: State = initialState,
  action: ActionType
) {
  const { type, payload, error } = action;

  switch (type) {
    case SET_LANGUAGE:
      return Object.assign({}, state, {
        error: null,
        lang: payload
      });
    case PUT_MODEL_PRESET:
      return Object.assign({}, state, {
        error: null,
        currentModel: payload
      });
    case CHANGE_VMT_STATUS:
      return Object.assign({}, state, {
        error: null,
        isVmtReady: payload
      });
    case CHANGE_IS_LIVE_STATUS:
      return Object.assign({}, state, {
        error: null,
        isLive: payload
      });
    case CHANGE_IS_MULTI_CAMERA_STATUS:
      return Object.assign({}, state, {
        error: null,
        isMultiCamera: payload
      });
    case SET_ZOOM_VALUE:
      return Object.assign({}, state, {
        zoomValue: payload
      });
    case SET_INTENSITY_VALUE:
      return Object.assign({}, state, {
        intensityLevel: payload
      });
    case SET_SPLITTER_VALUE:
      return Object.assign({}, state, {
        isSplitterActive: payload
      });
    case SET_CANVAS_ERROR:
      return Object.assign({}, state, {
        error
      });
    default:
      return state;
  }
}

/*Selectors */

const stateSelector = (state: Store) => state[moduleName];

export const selectLanguage = createSelector(
  stateSelector,
  (state: State) => state.lang
);
export const selectCurrentModel = createSelector(
  stateSelector,
  (state: State) => state.currentModel
);
export const selectIsVmtReady = createSelector(
  stateSelector,
  (state: State) => state.isVmtReady
);
export const selectIsLive = createSelector(
  stateSelector,
  (state: State) => state.isLive
);
export const selectIsMultiCamera = createSelector(
  stateSelector,
  (state: State) => state.isMultiCamera
);
export const selectZoomValue = createSelector(
  stateSelector,
  (state: State) => state.zoomValue
);
export const selectIntensityLevel = createSelector(
  stateSelector,
  (state: State) => state.intensityLevel
);
export const selectIsSplitterActive = createSelector(
  stateSelector,
  (state: State) => state.isSplitterActive
);
export const selectCanvasError = createSelector(
  stateSelector,
  (state: State) => state.error
);

// Customer Constants
export const selectCustomerConstants = createSelector(
  stateSelector,
  (state: State) => state.customerConstants
);

export const selectIsIntensitySplittersRequired = createSelector(
  stateSelector,
  (state: State) => state.customerConstants.isIntensitySplittersRequired
);
export const selectIsTranslatesRequired = createSelector(
  stateSelector,
  (state: State) => state.customerConstants.isTranslatesRequired
);
export const selectIsAnalyticRequired = createSelector(
  stateSelector,
  (state: State) => state.customerConstants.isAnalyticRequired
);
export const selectMainScreenTitle = createSelector(
  stateSelector,
  (state: State) => state.customerConstants.mainScreenTitle
);
export const selectMainScreenDescription = createSelector(
  stateSelector,
  (state: State) => state.customerConstants.mainScreenDescription
);
export const selectMainScreenDisclaimer = createSelector(
  stateSelector,
  (state: State) => state.customerConstants.mainScreenDisclaimer
);
export const selectLiveMakeUpButtonText = createSelector(
  stateSelector,
  (state: State) => state.customerConstants.liveMakeUpButtonText
);
export const selectLiveMakeUpButtonError = createSelector(
  stateSelector,
  (state: State) => state.customerConstants.liveMakeUpButtonError
);
export const selectUploadButtonText = createSelector(
  stateSelector,
  (state: State) => state.customerConstants.uploadButtonText
);
export const selectModelButtonText = createSelector(
  stateSelector,
  (state: State) => state.customerConstants.modelButtonText
);
export const selectHomeURL = createSelector(
  stateSelector,
  (state: State) => state.customerConstants.homeURL
);
export const selectMenuItems = createSelector(
  stateSelector,
  (state: State) => state.customerConstants.menu
);
export const selectBurgerBtnResolution = createSelector(
  stateSelector,
  (state: State) => state.customerConstants.burgerBtnResolution
);
export const selectIsAlgoFaceBrandingRequired = createSelector(
  stateSelector,
  (state: State) => state.customerConstants.isAlgoFaceBrandingRequired
);
export const selectModelScreenTitle = createSelector(
  stateSelector,
  (state: State) => state.customerConstants.modelScreenTitle
);
export const selectModelScreenBackButton = createSelector(
  stateSelector,
  (state: State) => state.customerConstants.backButton
);
export const selectModelsImages = createSelector(
  stateSelector,
  (state: State) => state.customerConstants.models
);
export const selectCanvasButtonTitleRemove = createSelector(
  stateSelector,
  (state: State) => state.customerConstants.canvasButtonTitleRemove
);
export const selectCanvasButtonTitleZoomIn = createSelector(
  stateSelector,
  (state: State) => state.customerConstants.canvasButtonTitleZoomIn
);
export const selectCanvasButtonTitleZoomOut = createSelector(
  stateSelector,
  (state: State) => state.customerConstants.canvasButtonTitleZoomOut
);
export const selectCanvasButtonTitleSplitter = createSelector(
  stateSelector,
  (state: State) => state.customerConstants.canvasButtonTitleSplitter
);
export const selectCanvasButtonTitleDownload = createSelector(
  stateSelector,
  (state: State) => state.customerConstants.canvasButtonTitleDownload
);
export const selectCanvasButtonTitleCamera = createSelector(
  stateSelector,
  (state: State) => state.customerConstants.canvasButtonTitleCamera
);
export const selectCanvasSplitterTitle = createSelector(
  stateSelector,
  (state: State) => state.customerConstants.canvasSplitterTitle
);
export const selectCanvasSplitterLow = createSelector(
  stateSelector,
  (state: State) => state.customerConstants.canvasSplitterLow
);
export const selectCanvasSplitterHigh = createSelector(
  stateSelector,
  (state: State) => state.customerConstants.canvasSplitterHigh
);
export const selectAccordionsProducts = createSelector(
  stateSelector,
  (state: State) => state.customerConstants.accordionsProducts
);
export const selectAccordionsStatement = createSelector(
  stateSelector,
  (state: State) => state.customerConstants.accordionsStatement
);
export const selectAccordionsLooks = createSelector(
  stateSelector,
  (state: State) => state.customerConstants.accordionsLooks
);
export const selectAccordionsTitle = createSelector(
  stateSelector,
  (state: State) => state.customerConstants.accordionsTitle
);

/* Action Creators */
export function setLanguage(payload: string) {
  return {
    type: SET_LANGUAGE,
    payload
  };
}
export function initCanvas(payload: ModelDataType | null) {
  return {
    type: INIT_CANVAS,
    payload
  };
}
export function setCanvasError(error: ErrorType) {
  return {
    type: SET_CANVAS_ERROR,
    error
  };
}
export function putCurrentModel(payload: ModelDataType) {
  return {
    type: PUT_MODEL_PRESET,
    payload
  };
}
export function changeVmtStatus(payload: boolean) {
  return {
    type: CHANGE_VMT_STATUS,
    payload
  };
}
export function changeIsLiveStatus(payload: boolean) {
  return {
    type: CHANGE_IS_LIVE_STATUS,
    payload
  };
}
export function checkIsMultiCameraStatus() {
  return {
    type: CHECK_MULTI_CAMERA_STATUS
  };
}
export function changeIsMultiCameraStatus(payload: boolean) {
  return {
    type: CHANGE_IS_MULTI_CAMERA_STATUS,
    payload
  };
}
export function toggleMultiCamera() {
  return {
    type: TOGGLE_MULTI_CAMERA
  };
}
export function changeZoomValue(payload: number) {
  return {
    type: CHANGE_ZOOM_VALUE,
    payload
  };
}
export function setZoomValue(payload: number) {
  return {
    type: SET_ZOOM_VALUE,
    payload
  };
}
export function setIntensity(payload: number) {
  return {
    type: SET_INTENSITY_VALUE,
    payload
  };
}
export function toggleSplitter(payload: boolean) {
  return {
    type: SET_SPLITTER_VALUE,
    payload
  };
}
export function downloadImage(payload: {
  outputCanvas: HTMLCanvasElement;
  actualWidth: number;
  splitterWidth: number;
}) {
  return {
    type: DOWNLOAD_IMAGE,
    payload
  };
}
export function resetCanvasView(resetLive: boolean = true) {
  return {
    type: RESET_CANVAS_VIEW,
    payload: resetLive
  };
}

/* Sagas */

function* initCanvasSaga({ payload }: ActionType): SagaIterator {
  try {
    const isLive = yield select(selectIsLive);
    let error = "";
    /** reset any previously applied makeup  */
    window.vmt_module._resetAllMakeup();
    window.SERVICES.vmtcore.setSplitterCanvasId("canvas_splitter");
    if (isLive) {
      window.SERVICES.video.init("video", "input_canvas");

      error = yield window.SERVICES.video.start(
        /** callback after video starts */
        () => {
          window.SERVICES.video.adjustSize();
          window.SERVICES.vmtcore.resetDetection();
          /** process frames from base canvas and display output on final canvas */
          window.SERVICES.vmtcore.init("input_canvas", "output_canvas");
          /**
           * live stream does not have to render makeup
           * separately, the following line will indicate
           * this information to vmtcore instance
           *
           */
          window.SERVICES.vmtcore.setIsLive(true);

          window.SERVICES.video.registerDrawCb(
            /** register a recursive callback */
            () => {
              window.SERVICES.vmtcore.renderLive();
            }
          );
          window.SERVICES.video.draw();
        },
        (error: ErrorType) => {
          console.log("callback", error);
        }
      );
    } else {
      /** register a callback that runs after image load */
      window.SERVICES.image.registerDrawCb(() => {
        /** set maximum iterations landmark tracker will take before it converges and returns result  */
        window.vmt_module._setMaxIterations(7);

        /** reset all previously made detections */
        window.SERVICES.vmtcore.resetDetection();

        /**
         * sets up the following pipeline or workflow
         * 1. reads images from input_canvas.
         * 2. perform landmark detection.
         * 3. may apply makeup.
         * 4. renders image onto output_canvas.
         * */
        window.SERVICES.vmtcore.init("input_canvas", "output_canvas");

        /** since we are dealing with images we set this to false */
        window.SERVICES.vmtcore.setIsLive(false);

        /** perform landmark detection */
        window.SERVICES.vmtcore.getDetection();

        /** if face successfully detected, may apply makeup */
        if (window.SERVICES.vmtcore.getIsDetected()) {
          window.SERVICES.vmtcore.render();
        }
      });

      /** add uploaded image to input canvas, and optionally resize that canvas */
      window.SERVICES.image.init("input_canvas", payload?.modelImage);
    }
    yield put(checkIsMultiCameraStatus());
    if (!!error) {
      yield put(setCanvasError(new Error(error)));
    }
  } catch (event) {
    setCanvasError(event);
  }
}

function* changeZoomValueSaga({ payload }: ActionType): SagaIterator {
  try {
    const isLive = yield select(selectIsLive);
    const zoomValue = yield select(selectZoomValue);
    const newValue = zoomValue + payload;
    if (newValue >= 0 && newValue < MAX_ZOOM) {
      yield put(setZoomValue(newValue));
      window.SERVICES.vmtcore.zoomTo(newValue);

      if (isLive) {
        window.SERVICES.vmtcore.zoomLiveRoutine();
      } else {
        window.SERVICES.vmtcore.render();
      }
    }
  } catch (event) {
    setCanvasError(event);
  }
}

function* setIntensitySaga({ payload }: ActionType): SagaIterator {
  try {
    const isLive = yield select(selectIsLive);
    window.vmt_module._setHeaviness(payload);
    if (!isLive) {
      window.SERVICES.vmtcore.render();
    }
  } catch (event) {
    setCanvasError(event);
  }
}

function toggleSplitterSaga({ payload }: ActionType) {
  try {
    window.SERVICES.vmtcore.setIsSplitterActive(payload);
  } catch (event) {
    setCanvasError(event);
  }
}

function* downloadImageSaga({ payload }: ActionType): SagaIterator {
  try {
    const vmt = window.SERVICES.vmtcore;
    const canvasBuffer = document.createElement("canvas");

    canvasBuffer.width = vmt.canvas_o.width;
    canvasBuffer.height = vmt.canvas_o.height;
    const canvasContext = canvasBuffer.getContext("2d");

    if (!!canvasContext) {
      canvasContext.drawImage(vmt.canvas_o, 0, 0);
      const isSplitterActive = yield select(selectIsSplitterActive);

      if (isSplitterActive) {
        const isLive = yield select(selectIsLive);
        const { outputCanvas, actualWidth, splitterWidth } = payload;
        //Map real size of canvas to vmt sizes
        const vmtCanvasSize = parseInt(
          outputCanvas.getAttribute("width") || "",
          10
        );
        const widthToCover = vmtCanvasSize * (splitterWidth / actualWidth);

        // Draw base part
        const baseCanvas = isLive ? vmt.canvas_i : vmt.canvas_splitter;
        canvasContext.drawImage(
          baseCanvas,
          0,
          0,
          widthToCover,
          vmt.canvas_o.height,
          0,
          0,
          widthToCover,
          vmt.canvas_o.height
        );
        canvasContext.fillStyle = "white";
        canvasContext.rect(widthToCover - 2, 0, 4, vmt.canvas_o.height);
        canvasContext.fill();
      }

      canvasContext.fillStyle = "#af9a7f";
      canvasContext.font = "14px robo-bold";
      canvasContext.fillText("Algoface", 5, vmt.canvas_o.height - 5);

      //Download
      const link = document.createElement("a");
      const date = new Date();
      link.download = "Algoface" + date.toISOString().replace(".", "_") + ".png";
      link.href = canvasBuffer.toDataURL("image/png");
      link.click();

      //Clean up
      canvasBuffer.remove();
      link.remove();
    }
  } catch (event) {
    setCanvasError(event);
  }
}

function* checkMultiCameraStatusSaga() {
  try {
    const activeDevices = yield window.SERVICES.video.getActiveDevices();

    if (activeDevices?.length > 1) {
      yield put(changeIsMultiCameraStatus(true));
    } else if (activeDevices?.length < 1) {
      throw new Error(
        'Camera may be disabled. Please enable the camera access in settings for this web page to use our app"'
      );
    }
  } catch (event) {
    setCanvasError(event);
  }
}

function toggleMultiCameraSaga() {
  try {
    window.SERVICES.video.toggleCamera(true);
  } catch (event) {
    setCanvasError(event);
  }
}

function* resetCanvasViewSaga({ payload }: ActionType): SagaIterator {
  try {
    yield put(resetMakeUpStatus());
    window.vmt_module._resetAllMakeup();
    const isSplitterActive = yield select(selectIsSplitterActive);
    const isLive = yield select(selectIsLive);
    window.SERVICES.vmtcore.zoomTo(0);
    yield put(setZoomValue(0));
    yield put(setIntensity(75));
    if (isSplitterActive) {
      yield put(toggleSplitter(!isSplitterActive));
    }
    if (isLive && payload) {
      yield put(changeIsLiveStatus(false));
      window.SERVICES.vmtcore.zoomLiveRoutine();
      window.SERVICES.video.pause();
    } else if (isLive) {
      window.SERVICES.vmtcore.zoomLiveRoutine();
    } else {
      window.SERVICES.vmtcore.render();
    }
  } catch (event) {
    setCanvasError(event);
  }
}

export const saga = function*() {
  yield all([
    takeEvery(INIT_CANVAS, initCanvasSaga),
    takeEvery(CHANGE_ZOOM_VALUE, changeZoomValueSaga),
    takeEvery(SET_INTENSITY_VALUE, setIntensitySaga),
    takeEvery(SET_SPLITTER_VALUE, toggleSplitterSaga),
    takeEvery(DOWNLOAD_IMAGE, downloadImageSaga),
    takeEvery(CHECK_MULTI_CAMERA_STATUS, checkMultiCameraStatusSaga),
    takeEvery(TOGGLE_MULTI_CAMERA, toggleMultiCameraSaga),
    takeEvery(RESET_CANVAS_VIEW, resetCanvasViewSaga)
  ]);
};
