// identify user agent

navigator.osname = "Unknown OS";
if (navigator.appVersion.indexOf("Win") != -1) navigator.osname = "Windows";
if (navigator.appVersion.indexOf("Mac") != -1) navigator.osname = "MacOS";
if (navigator.appVersion.indexOf("X11") != -1) navigator.osname = "UNIX";
if (navigator.appVersion.indexOf("Linux") != -1) navigator.osname = "Linux";

navigator.sayswho = (function() {
  var ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return "IE " + (tem[1] || "");
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null)
      return tem
        .slice(1)
        .join(" ")
        .replace("OPR", "Opera");
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(" ");
})();

navigator.isIPhone = navigator.platform.toLowerCase() === "iphone";
navigator.isIOS =
  /iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
navigator.isTouch = "ontouchstart" in window || "onmsgesturechange" in window; // works on most browsers  // works on IE10 with some false positives

function makeFormData(data) {
  var fd = new FormData();
  Object.keys(data).forEach(e => {
    fd.append(e, data[e]);
  });
  return fd;
}

function bufferToBase64(buf) {
  var binstr = Array.prototype.map
    .call(buf, function(ch) {
      return String.fromCharCode(ch);
    })
    .join("");
  return btoa(binstr);
}

window.calculateAspectRatioFit = function (srcWidth, srcHeight, maxWidth, maxHeight) {
  var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return {
    width: srcWidth * ratio,
    height: srcHeight * ratio
  };
}

window.hermiteFilterResize = function (canvas, width, height, resize_canvas) {
  var width_source = canvas.width;
  var height_source = canvas.height;
  width = Math.round(width);
  height = Math.round(height);

  var ratio_w = width_source / width;
  var ratio_h = height_source / height;
  var ratio_w_half = Math.ceil(ratio_w / 2);
  var ratio_h_half = Math.ceil(ratio_h / 2);

  var ctx = canvas.getContext("2d");
  var img = ctx.getImageData(0, 0, width_source, height_source);
  var img2 = ctx.createImageData(width, height);
  var data = img.data;
  var data2 = img2.data;

  for (var j = 0; j < height; j++) {
    for (var i = 0; i < width; i++) {
      var x2 = (i + j * width) * 4;
      var weight = 0;
      var weights = 0;
      var weights_alpha = 0;
      var gx_r = 0;
      var gx_g = 0;
      var gx_b = 0;
      var gx_a = 0;
      var center_y = (j + 0.5) * ratio_h;
      var yy_start = Math.floor(j * ratio_h);
      var yy_stop = Math.ceil((j + 1) * ratio_h);
      for (var yy = yy_start; yy < yy_stop; yy++) {
        var dy = Math.abs(center_y - (yy + 0.5)) / ratio_h_half;
        var center_x = (i + 0.5) * ratio_w;
        var w0 = dy * dy; //pre-calc part of w
        var xx_start = Math.floor(i * ratio_w);
        var xx_stop = Math.ceil((i + 1) * ratio_w);
        for (var xx = xx_start; xx < xx_stop; xx++) {
          var dx = Math.abs(center_x - (xx + 0.5)) / ratio_w_half;
          var w = Math.sqrt(w0 + dx * dx);
          if (w >= 1) {
            //pixel too far
            continue;
          }
          //hermite filter
          weight = 2 * w * w * w - 3 * w * w + 1;
          var pos_x = 4 * (xx + yy * width_source);
          //alpha
          gx_a += weight * data[pos_x + 3];
          weights_alpha += weight;
          //colors
          if (data[pos_x + 3] < 255) weight = (weight * data[pos_x + 3]) / 250;
          gx_r += weight * data[pos_x];
          gx_g += weight * data[pos_x + 1];
          gx_b += weight * data[pos_x + 2];
          weights += weight;
        }
      }
      data2[x2] = gx_r / weights;
      data2[x2 + 1] = gx_g / weights;
      data2[x2 + 2] = gx_b / weights;
      data2[x2 + 3] = gx_a / weights_alpha;
    }
  }
  //clear and resize canvas
  if (resize_canvas === true) {
    canvas.width = width;
    canvas.height = height;
  } else {
    ctx.clearRect(0, 0, width_source, height_source);
  }

  //draw
  ctx.putImageData(img2, 0, 0);
}

function removeElementById(elm_id) {
  var element = document.getElementById(elm_id);
  element.parentNode.removeChild(element);
}

function touchInit(id) {
  document
    .getElementById(id)
    .addEventListener("touchstart", touchHandler, true);
  document.getElementById(id).addEventListener("touchmove", touchHandler, true);
  document.getElementById(id).addEventListener("touchend", touchHandler, true);
  document
    .getElementById(id)
    .addEventListener("touchcancel", touchHandler, true);
}
function touchDeInit(id) {
  document.getElementById(id).removeEventListener("touchstart");
  document.getElementById(id).removeEventListener("touchmove");
  document.getElementById(id).removeEventListener("touchend");
  document.getElementById(id).removeEventListener("touchcancel");
}

function touchHandler(event) {
  var touch = event.changedTouches[0];

  var simulatedEvent = document.createEvent("MouseEvent");
  simulatedEvent.initMouseEvent(
    {
      touchstart: "mousedown",
      touchmove: "mousemove",
      touchend: "mouseup"
    }[event.type],
    true,
    true,
    window,
    1,
    touch.screenX,
    touch.screenY,
    touch.clientX,
    touch.clientY,
    false,
    false,
    false,
    false,
    0,
    null
  );

  touch.target.dispatchEvent(simulatedEvent);
  event.preventDefault();
}

// function simulateTouchEvents(oo, bIgnoreChilds) {
//     if (!$(oo)[0]) {
//         return false;
//     }
//
//     if (!window.__touchTypes) {
//         window.__touchTypes = {
//             touchstart: 'mousedown',
//             touchmove: 'mousemove',
//             touchend: 'mouseup'
//         };
//         window.__touchInputs = {
//             INPUT: 1,
//             TEXTAREA: 1,
//             SELECT: 1,
//             OPTION: 1,
//             'input': 1,
//             'textarea': 1,
//             'select': 1,
//             'option': 1
//         };
//     }
//
//     $(oo).bind('touchstart touchmove touchend', function (ev) {
//         var bSame = (ev.target == this);
//         if (bIgnoreChilds && !bSame) {
//             return;
//         }
//
//         var b = (!bSame && ev.target.__ajqmeclk), // Get if object is already tested or input type
//             e = ev.originalEvent;
//         if (b === true || !e.touches || e.touches.length > 1 || !window.__touchTypes[e.type]) {
//             return;
//         } //allow multi-touch gestures to work
//
//         var oEv = (!bSame && typeof b != 'boolean') ? $(ev.target).data('events') : false,
//             b = (!bSame) ? (ev.target.__ajqmeclk = oEv ? (oEv['click'] || oEv['mousedown'] || oEv['mouseup'] || oEv['mousemove']) : false) : false;
//
//         if (b || window.__touchInputs[ev.target.tagName]) {
//             return;
//         } //allow default clicks to work (and on inputs)
//
//         // https://developer.mozilla.org/en/DOM/event.initMouseEvent for API
//         var touch = e.changedTouches[0],
//             newEvent = document.createEvent("MouseEvent");
//         newEvent.initMouseEvent(window.__touchTypes[e.type], true, true, window, 1,
//             touch.screenX, touch.screenY,
//             touch.clientX, touch.clientY, false,
//             false, false, false, 0, null);
//
//         touch.target.dispatchEvent(newEvent);
//         e.preventDefault();
//         ev.stopImmediatePropagation();
//         ev.stopPropagation();
//         ev.preventDefault();
//     }, {
//         passive: true
//     });
//     return true;
// }

function RGBAToHexA(r, g, b, a) {
  r = r.toString(16);
  g = g.toString(16);
  b = b.toString(16);
  a = Math.round(a * 255).toString(16);

  if (r.length == 1) r = "0" + r;
  if (g.length == 1) g = "0" + g;
  if (b.length == 1) b = "0" + b;
  if (a.length == 1) a = "0" + a;

  return "#" + r + g + b + a;
}

/** One Liners */
const average = arr => arr.reduce((sume, el) => sume + el, 0) / arr.length;

const capitalize = s => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};
window.capitalize = capitalize;
