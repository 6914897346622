import React, { Component } from "react";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from "react-intl";
import { Store } from "redux";
import Input, { Range } from "react-input-range";
import { connect } from "react-redux";

import {
  selectCanvasSplitterHigh,
  selectCanvasSplitterLow,
  selectCanvasSplitterTitle
} from "ducks/app";

import styles from "./InputRange.module.scss";

interface Props extends WrappedComponentProps {
  defaultValue: number;
  maxValue: number;
  minValue: number;
  step: number;
  canvasSplitterTitle: string;
  canvasSplitterLow: string;
  canvasSplitterHigh: string;
  onChange: (intensity: number) => any;
  resetSlider?: boolean;
}

interface State {
  intensity: number;
}

class InputRange extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      intensity: props.defaultValue
    };
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    if (
      prevProps.resetSlider !== this.props.resetSlider &&
      this.props.resetSlider
    ) {
      this.setState({ intensity: this.props.defaultValue });
    }
  }

  public changeIntensity = (value: number | Range) => {
    this.setState({ intensity: value as number });
  };

  public onChangeComplete = () => {
    this.props.onChange(this.state.intensity);
  };

  public render() {
    return (
      <div className={styles.rangeWrapper}>
        <FormattedMessage id={this.props.canvasSplitterLow} />
        <div
          className={styles.rangeContainer}
          title={this.props.intl.formatMessage({
            id: this.props.canvasSplitterTitle
          })}
        >
          <Input
            value={this.state.intensity}
            maxValue={this.props.maxValue}
            minValue={this.props.minValue}
            step={1}
            onChange={this.changeIntensity}
            onChangeComplete={this.onChangeComplete}
            classNames={{
              activeTrack: styles.activeTrack,
              disabledInputRange: styles.disabledInputRange,
              inputRange: styles.inputRange,
              labelContainer: styles.hidden,
              maxLabel: styles.hidden,
              minLabel: styles.hidden,
              slider: styles.slider,
              sliderContainer: styles.sliderContainer,
              track: styles.track,
              valueLabel: styles.hidden
            }}
          />
        </div>
        <FormattedMessage id={this.props.canvasSplitterHigh} />
      </div>
    );
  }
}

const mapStateToProps = (state: Store) => ({
  canvasSplitterTitle: selectCanvasSplitterTitle(state),
  canvasSplitterLow: selectCanvasSplitterLow(state),
  canvasSplitterHigh: selectCanvasSplitterHigh(state)
});

export default injectIntl(connect(mapStateToProps)(InputRange));
