import Look_1 from "static/img/looks/1.jpg";
import Look_2 from "static/img/looks/2.jpg";
import Look_3 from "static/img/looks/3.jpg";
import Look_4 from "static/img/looks/4.jpg";
import Look_5 from "static/img/looks/5.jpg";
import Look_6 from "static/img/looks/6.jpg";
import Look_7 from "static/img/looks/7.jpg";
import Look_8 from "static/img/looks/8.jpg";
import Look_9 from "static/img/looks/9.jpg";
import Look_10 from "static/img/looks/10.jpg";
import Look_11 from "static/img/looks/11.jpg";
import Look_12 from "static/img/looks/12.jpg";
import Look_13 from "static/img/looks/13.jpg";
import Look_14 from "static/img/looks/14.jpg";
import Look_15 from "static/img/looks/15.jpg";

export const eyeShadowsLooks = {
  "1": {
    isActive: false,
    image: null,
    entities: [
      {
        idx: 0,
        sku_id: "EYESHADOW_0",
        sku_name: "EYESHADOW_0",
        type: "EYESHADOW",
        r: 192,
        g: 130,
        b: 73,
        a: 0.65,
        defaultOpacity: 0.65,
        mask: "eyeshadow_1",
        shimmer_mask: "eyeshadow_shimmer",
        shimmer_intensity: 1
      }
    ]
  },
  "2": {
    isActive: false,
    image: null,
    entities: [
      {
        idx: 1,
        sku_id: "EYESHADOW_1",
        sku_name: "EYESHADOW_1",
        type: "EYESHADOW",
        r: 102,
        g: 173,
        b: 193,
        a: 0.65,
        defaultOpacity: 0.65,
        mask: "eyeshadow_2"
      },
      {
        idx: 2,
        sku_id: "EYESHADOW_1",
        sku_name: "EYESHADOW_1",
        type: "EYESHADOW",
        r: 20,
        g: 162,
        b: 210,
        a: 0.65,
        defaultOpacity: 0.65,
        mask: "eyeshadow_3"
      },
      {
        idx: 3,
        sku_id: "EYESHADOW_1",
        sku_name: "EYESHADOW_1",
        type: "EYESHADOW",
        r: 48,
        g: 55,
        b: 83,
        a: 0.65,
        defaultOpacity: 0.65,
        mask: "eyeshadow_4"
      }
    ]
  },
  "3": {
    isActive: false,
    image: null,
    entities: [
      {
        idx: 4,
        sku_id: "EYESHADOW_4",
        sku_name: "EYESHADOW_4",
        type: "EYESHADOW",
        r: 246,
        g: 198,
        b: 178,
        a: 0.65,
        defaultOpacity: 0.65,
        mask: "eyeshadow_5"
      },
      {
        idx: 5,
        sku_id: "EYESHADOW_4",
        sku_name: "EYESHADOW_4",
        type: "EYESHADOW",
        r: 210,
        g: 164,
        b: 164,
        a: 0.65,
        defaultOpacity: 0.65,
        mask: "eyeshadow_6"
      },
      {
        idx: 6,
        sku_id: "EYESHADOW_4",
        sku_name: "EYESHADOW_4",
        type: "EYESHADOW",
        r: 101,
        g: 63,
        b: 54,
        a: 0.65,
        defaultOpacity: 0.65,
        mask: "eyeshadow_7"
      },
      {
        idx: 7,
        sku_id: "EYESHADOW_4",
        sku_name: "EYESHADOW_4",
        type: "EYESHADOW",
        r: 225,
        g: 117,
        b: 189,
        a: 0.65,
        defaultOpacity: 0.65,
        mask: "eyeshadow_8"
      },
      {
        idx: 8,
        sku_id: "EYESHADOW_4",
        sku_name: "EYESHADOW_4",
        type: "EYESHADOW",
        r: 246,
        g: 198,
        b: 178,
        a: 0.65,
        defaultOpacity: 0.65,
        mask: "eyeshadow_9"
      }
    ]
  },
  "4": {
    isActive: false,
    image: null,
    entities: [
      {
        idx: 9,
        sku_id: "EYESHADOW_9",
        sku_name: "EYESHADOW_9",
        type: "EYESHADOW",
        r: 163,
        g: 120,
        b: 101,
        a: 0.65,
        defaultOpacity: 0.65,
        mask: "eyeshadow_10"
      },
      {
        idx: 10,
        sku_id: "EYESHADOW_9",
        sku_name: "EYESHADOW_9",
        type: "EYESHADOW",
        r: 91,
        g: 97,
        b: 113,
        a: 0.65,
        defaultOpacity: 0.65,
        mask: "eyeshadow_11"
      }
    ]
  },
  "5": {
    isActive: false,
    image: null,
    entities: [
      {
        idx: 11,
        sku_id: "EYESHADOW_11",
        sku_name: "EYESHADOW_11",
        type: "EYESHADOW",
        r: 227,
        g: 118,
        b: 171,
        a: 0.65,
        defaultOpacity: 0.65,
        mask: "eyeshadow_12"
      },
      {
        idx: 12,
        sku_id: "EYESHADOW_11",
        sku_name: "EYESHADOW_12",
        type: "EYESHADOW",
        r: 115,
        g: 154,
        b: 153,
        a: 0.65,
        defaultOpacity: 0.65,
        mask: "eyeshadow_13"
      }
    ]
  },
  "6": {
    isActive: false,
    image: null,
    entities: [
      {
        idx: 13,
        sku_id: "EYESHADOW_13",
        sku_name: "EYESHADOW_13",
        type: "EYESHADOW",
        r: 154,
        g: 145,
        b: 146,
        a: 0.65,
        defaultOpacity: 0.65,
        mask: "eyeshadow_14"
      },
      {
        idx: 14,
        sku_id: "EYESHADOW_13",
        sku_name: "EYESHADOW_13",
        type: "EYESHADOW",
        r: 89,
        g: 78,
        b: 84,
        a: 0.65,
        defaultOpacity: 0.65,
        mask: "eyeshadow_15"
      }
    ]
  },
  "7": {
    isActive: false,
    image: null,
    entities: [
      {
        idx: 15,
        sku_id: "EYESHADOW_15",
        sku_name: "EYESHADOW_15",
        type: "EYESHADOW",
        r: 221,
        g: 156,
        b: 199,
        a: 0.65,
        defaultOpacity: 0.65,
        mask: "eyeshadow_16"
      },
      {
        idx: 16,
        sku_id: "EYESHADOW_15",
        sku_name: "EYESHADOW_15",
        type: "EYESHADOW",
        r: 166,
        g: 117,
        b: 161,
        a: 0.65,
        defaultOpacity: 0.65,
        mask: "eyeshadow_17"
      },
      {
        idx: 17,
        sku_id: "EYESHADOW_15",
        sku_name: "EYESHADOW_15",
        type: "EYESHADOW",
        r: 65,
        g: 45,
        b: 57,
        a: 0.65,
        defaultOpacity: 0.65,
        mask: "eyeshadow_18"
      }
    ]
  }
};

export const fullLooks = {
  "1": {
    isActive: false,
    image: Look_1,
    entities: [
      {
        idx: 0,
        sku_id: "LIPSTICK_0",
        sku_name: "LIPSTICK_0",
        type: "LIPSTICK",
        r: 237,
        g: 99,
        b: 17,
        a: 0.4,
        defaultOpacity: 0.4,
        finish_intensity: 0.4,
        finish_type: 1,
        glitter_mask: "lipGlitter_2",
        glitter_intensity: 0,
        shimmer_mask: "lipShimmer_1",
        shimmer_intensity: 0
      },
      {
        idx: 0,
        sku_id: "EYELASH_0",
        sku_name: "EYELASH_0",
        type: "EYELASH",
        r: 25,
        g: 25,
        b: 25,
        a: 0.25,
        defaultOpacity: 0.25,
        mask: "eyelash_1"
      },
      {
        idx: 0,
        sku_id: "EYELINER_0",
        sku_name: "EYELINER_0",
        type: "EYELINER",
        r: 33,
        g: 35,
        b: 35,
        a: 0.25,
        defaultOpacity: 0.25,
        mask: "eyeliner_1"
      }
    ]
  },
  "2": {
    isActive: false,
    image: Look_2,
    entities: [
      {
        idx: 1,
        sku_id: "LIPSTICK_0",
        sku_name: "LIPSTICK_0",
        type: "LIPSTICK",
        r: 203,
        g: 62,
        b: 106,
        a: 0.6392156862745098,
        defaultOpacity: 0.6392156862745098,
        finish_intensity: 0.15,
        finish_type: 1,
        glitter_mask: "lipGlitter_2",
        glitter_intensity: 0,
        shimmer_mask: "lipShimmer_1",
        shimmer_intensity: 0.15
      },
      {
        idx: 0,
        sku_id: "BLUSH_0",
        sku_name: "BLUSH_0",
        type: "BLUSH",
        r: 217,
        g: 146,
        b: 117,
        a: 0.12,
        defaultOpacity: 0.12,
        shimmer_mask: "blush_shimmer",
        shimmer_intensity: 0,
        mask: "blush_3"
      },
      {
        idx: 0,
        sku_id: "EYESHADOW_0",
        sku_name: "EYESHADOW_0",
        type: "EYESHADOW",
        r: 133,
        g: 73,
        b: 46,
        a: 0.35,
        defaultOpacity: 0.35,
        shimmer_mask: "eyeshadow_shimmer",
        shimmer_intensity: 0,
        mask: "eyeshadow_1"
      },
      {
        idx: 0,
        sku_id: "EYELASH_0",
        sku_name: "EYELASH_0",
        type: "EYELASH",
        r: 25,
        g: 25,
        b: 25,
        a: 0.45,
        defaultOpacity: 0.45,
        mask: "eyelash_2"
      },
      {
        idx: 0,
        sku_id: "EYELINER_0",
        sku_name: "EYELINER_0",
        type: "EYELINER",
        r: 35,
        g: 35,
        b: 35,
        a: 0.45,
        defaultOpacity: 0.45,
        mask: "eyeliner_2"
      },
      {
        idx: 0,
        sku_id: "EYEBROW_0",
        sku_name: "EYEBROW_0",
        type: "EYEBROW",
        r: 103,
        g: 77,
        b: 63,
        a: 0.55,
        defaultOpacity: 0.55,
        mask: "eyebrow_powder"
      },
      {
        idx: 0,
        sku_id: "CONTOUR_0",
        sku_name: "CONTOUR_0",
        type: "CONTOUR",
        r: 228,
        g: 211,
        b: 182,
        a: 0.33,
        defaultOpacity: 0.33,
        mask: "bronzer_fullface_contour_3"
      }
    ]
  },
  "3": {
    isActive: false,
    image: Look_3,
    entities: [
      {
        idx: 0,
        sku_id: "LIPSTICK_0",
        sku_name: "LIPSTICK_0",
        type: "LIPSTICK",
        r: 188,
        g: 136,
        b: 109,
        a: 0.67,
        defaultOpacity: 0.67,
        finish_intensity: 1,
        finish_type: 2,
        glitter_mask: "lipGlitter_2",
        glitter_intensity: 0,
        shimmer_mask: "lipShimmer_1",
        shimmer_intensity: 0.1
      },
      {
        idx: 0,
        sku_id: "EYESHADOW_0",
        sku_name: "EYESHADOW_0",
        type: "EYESHADOW",
        r: 150,
        g: 93,
        b: 69,
        a: 0.4,
        defaultOpacity: 0.4,
        shimmer_mask: "eyeshadow_shimmer",
        shimmer_intensity: 0,
        mask: "eyeshadow_2"
      },
      {
        idx: 0,
        sku_id: "EYELASH_0",
        sku_name: "EYELASH_0",
        type: "EYELASH",
        r: 0,
        g: 0,
        b: 0,
        a: 0.5,
        defaultOpacity: 0.5,
        mask: "eyelash_3"
      },
      {
        idx: 0,
        sku_id: "EYELINER_0",
        sku_name: "EYELINER_0",
        type: "EYELINER",
        r: 246,
        g: 225,
        b: 189,
        a: 0.33,
        mask: "eyeliner_6",
        defaultOpacity: 0.5
      },
      {
        idx: 0,
        sku_id: "EYEBROW_0",
        sku_name: "EYEBROW_0",
        type: "EYEBROW",
        r: 167,
        g: 133,
        b: 106,
        a: 0.94,
        mask: "eyebrow_powder_5",
        defaultOpacity: 0.5
      },
      {
        idx: 0,
        sku_id: "BLUSH_0",
        sku_name: "BLUSH_0",
        type: "BLUSH",
        r: 191,
        g: 82,
        b: 42,
        a: 0.0784313725490196,
        defaultOpacity: 0.0784313725490196,
        shimmer_mask: "blush_shimmer",
        shimmer_intensity: 0,
        mask: "blush_2"
      },
      {
        idx: 0,
        sku_id: "BRONZER_0",
        sku_name: "BRONZER_0",
        type: "BRONZER",
        r: 81,
        g: 70,
        b: 60,
        a: 0.44,
        mask: "bronzer_fullface",
        defaultOpacity: 0.44
      },
      {
        idx: 1,
        sku_id: "CONTOUR_1",
        sku_name: "CONTOUR_1",
        type: "CONTOUR",
        r: 228,
        g: 211,
        b: 182,
        a: 0.33,
        mask: "bronzer_fullface_contour_3",
        defaultOpacity: 0.33
      }
    ]
  },
  "4": {
    isActive: false,
    image: Look_4,
    entities: [
      {
        idx: 0,
        sku_id: "FOUNDATION_0",
        sku_name: "FOUNDATION_0",
        type: "FOUNDATION",
        r: 193,
        g: 156,
        b: 101,
        a: 0.1411764705882353,
        mask: "foundation_fullface",
        defaultOpacity: 0.1411764705882353
      },
      {
        idx: 2,
        sku_id: "BLENDER_2",
        sku_name: "BLENDER_2",
        type: "BLENDER",
        r: 241,
        g: 193,
        b: 171,
        a: 0.3,
        defaultOpacity: 0.3
      }
      ,
      {
        idx: 0,
        sku_id: "BLUSH_0",
        sku_name: "BLUSH_0",
        type: "BLUSH",
        r: 146,
        g: 93,
        b: 62,
        a: 0.1,
        shimmer_mask: "blush_shimmer",
        shimmer_intensity: 0.3,
        mask: "blush_2",
        defaultOpacity: 0.1
      }
      , {
        idx: 1,
        sku_id: "LIPLINER_1",
        sku_name: "LIPLINER_1",
        type: "LIPLINER",
        r: 191,
        g: 101,
        b: 101,
        a: 0.75,
        mask: "lipliner_2",
        defaultOpacity: 0.75
      }
      ,
      {
        idx: 1,
        sku_id: "BRONZER_1",
        sku_name: "BRONZER_1",
        type: "BRONZER",
        r: 56,
        g: 49,
        b: 42,
        a: 0.3,
        mask: "bronzer_fullface",
        defaultOpacity: 0.3
      }
      ,
      {
        idx: 1,
        sku_id: "CONTOUR_1",
        sku_name: "CONTOUR_1",
        type: "CONTOUR",
        r: 231,
        g: 214,
        b: 184,
        a: 0.2,
        mask: "bronzer_fullface_contour_3",
        defaultOpacity: 0.2
      }
      ,
      {
        idx: 0,
        sku_id: "LIPSTICK_0",
        sku_name: "LIPSTICK_0",
        type: "LIPSTICK",
        r: 246,
        g: 145,
        b: 145,
        a: 0.6901960784313725,
        finish_intensity: 1,
        finish_type: 0,
        glitter_mask: "lipGlitter_2",
        glitter_intensity: 0,
        shimmer_mask: "lipShimmer_1",
        shimmer_intensity: 0.15,
        defaultOpacity: 0.6901960784313725
      }
      ,
      {
        idx: 0,
        sku_id: "EYESHADOW_0",
        sku_name: "EYESHADOW_0",
        type: "EYESHADOW",
        r: 127,
        g: 81,
        b: 107,
        a: 0.5686274509803921,
        shimmer_mask: "eyeshadow_shimmer",
        shimmer_intensity: 0.3,
        mask: "eyeshadow_5",
        defaultOpacity: 0.5686274509803921
      }
      ,
      {
        idx: 0,
        sku_id: "EYELASH_0",
        sku_name: "EYELASH_0",
        type: "EYELASH",
        r: 25,
        g: 25,
        b: 25,
        a: 0.55,
        mask: "eyelash_4",
        defaultOpacity: 0.55
      }
      ,
      {
        idx: 0,
        sku_id: "EYELINER_0",
        sku_name: "EYELINER_0",
        type: "EYELINER",
        r: 33,
        g: 35,
        b: 35,
        a: 0.35,
        mask: "eyeliner_3",
        defaultOpacity: 0.35
      }
      ,
      {
        idx: 0,
        sku_id: "EYEBROW_0",
        sku_name: "EYEBROW_0",
        type: "EYEBROW",
        r: 146,
        g: 108,
        b: 77,
        a: 0.5019607843137255,
        mask: "eyebrow_powder",
        defaultOpacity: 0.5019607843137255
      }
    ]
  },
  "5": {
    isActive: false,
    image: Look_5,
    entities: [
      {
        idx: 0,
        sku_id: "BLENDER_0",
        sku_name: "BLENDER_0",
        type: "BLENDER",
        r: 241,
        g: 193,
        b: 171,
        a: 0.25,
        defaultOpacity: 0.25
      }
      ,
      {
        idx: 0,
        sku_id: "BLUSH_0",
        sku_name: "BLUSH_0",
        type: "BLUSH",
        r: 217,
        g: 128,
        b: 117,
        a: 0.15,
        shimmer_mask: "blush_shimmer",
        shimmer_intensity: 0,
        mask: "blush_1",
        defaultOpacity: 0.15
      }
      , {
        idx: 0,
        sku_id: "CONTOUR_0",
        sku_name: "CONTOUR_0",
        type: "CONTOUR",
        r: 230,
        g: 211,
        b: 182,
        a: 0.4,
        mask: "bronzer_fullface_contour_3",
        defaultOpacity: 0.4
      }
      ,
      {
        idx: 1,
        sku_id: "LIPSTICK_1",
        sku_name: "LIPSTICK_1",
        type: "LIPSTICK",
        r: 255,
        g: 88,
        b: 133,
        a: 0.7019607843137254,
        finish_intensity: 1,
        finish_type: 1,
        glitter_mask: "lipGlitter_2",
        glitter_intensity: 0,
        shimmer_mask: "lipShimmer_1",
        shimmer_intensity: 0.25,
        defaultOpacity: 0.7019607843137254
      }
      , {
        idx: 0,
        sku_id: "EYESHADOW_0",
        sku_name: "EYESHADOW_0",
        type: "EYESHADOW",
        r: 154,
        g: 122,
        b: 94,
        a: 0.74,
        shimmer_mask: "eyeshadow_shimmer",
        shimmer_intensity: 0.05,
        mask: "eyeshadow_11",
        defaultOpacity: 0.74
      }
      ,
      {
        idx: 0,
        sku_id: "EYELASH_0",
        sku_name: "EYELASH_0",
        type: "EYELASH",
        r: 25,
        g: 25,
        b: 25,
        a: 0.4,
        mask: "eyelash_5",
        defaultOpacity: 0.4
      }
      ,
      {
        idx: 1,
        sku_id: "EYELINER_1",
        sku_name: "EYELINER_1",
        type: "EYELINER",
        r: 244,
        g: 244,
        b: 222,
        a: 0.5,
        mask: "eyeliner_10",
        defaultOpacity: 0.5
      }
      ,
      {
        idx: 0,
        sku_id: "EYEBROW_0",
        sku_name: "EYEBROW_0",
        type: "EYEBROW",
        r: 168,
        g: 133,
        b: 105,
        a: 0.88,
        mask: "eyebrow_powder",
        defaultOpacity: 0.88
      }

    ]
  },
  "6": {
    isActive: false,
    image: Look_6,
    entities: [

      {
        idx: 0,
        sku_id: "FOUNDATION_0",
        sku_name: "FOUNDATION_0",
        type: "FOUNDATION",
        r: 202,
        g: 149,
        b: 122,
        a: 0.22,
        mask: "foundation_fullface",
        defaultOpacity: 0.22

      }
      ,
      {
        idx: 0,
        sku_id: "BLUSH_0",
        sku_name: "BLUSH_0",
        type: "BLUSH",
        r: 222,
        g: 146,
        b: 122,
        a: 0.22,
        shimmer_mask: "blush_shimmer",
        shimmer_intensity: 0,
        mask: "blush_1",
        defaultOpacity: 0.22
      }
      ,
      {
        idx: 0,
        sku_id: "BRONZER_0",
        sku_name: "BRONZER_0",
        type: "BRONZER",
        r: 169,
        g: 109,
        b: 78,
        a: 0.2,
        mask: "bronzer_sunkiss",
        defaultOpacity: 0.2
      }
      ,
      {
        idx: 0,
        sku_id: "CONTOUR_0",
        sku_name: "CONTOUR_0",
        type: "CONTOUR",
        r: 121,
        g: 86,
        b: 60,
        a: 0.15,
        mask: "bronzer_fullface_contour_2",
        defaultOpacity: 0.15
      }
      ,
      {
        idx: 0,
        sku_id: "LIPSTICK_0",
        sku_name: "LIPSTICK_0",
        type: "LIPSTICK",
        r: 200,
        g: 137,
        b: 116,
        a: 0.6,
        finish_intensity: 0.8,
        finish_type: 2,
        glitter_mask: "lipGlitter_2",
        glitter_intensity: 0,
        shimmer_mask: "lipShimmer_1",
        shimmer_intensity: 0,
        defaultOpacity: 0.6
      }
      ,
      {
        idx: 0,
        sku_id: "EYESHADOW_0",
        sku_name: "EYESHADOW_0",
        type: "EYESHADOW",
        r: 154,
        g: 116,
        b: 86,
        a: 1,
        shimmer_mask: "eyeshadow_shimmer",
        shimmer_intensity: 0,
        mask: "eyeshadow_6",
        defaultOpacity: 1
      }
      ,
      {
        idx: 0,
        sku_id: "EYELASH_0",
        sku_name: "EYELASH_0",
        type: "EYELASH",
        r: 25,
        g: 25,
        b: 25,
        a: 0.44,
        mask: "eyelash_6",
        defaultOpacity: 0.44
      }
      ,
      {
        idx: 0,
        sku_id: "EYELINER_0",
        sku_name: "EYELINER_0",
        type: "EYELINER",
        r: 33,
        g: 35,
        b: 35,
        a: 0.33,
        mask: "eyeliner_3",
        defaultOpacity: 0.33
      }
      ,
      {
        idx: 0,
        sku_id: "EYEBROW_0",
        sku_name: "EYEBROW_0",
        type: "EYEBROW",
        r: 106,
        g: 90,
        b: 77,
        a: 0.42,
        mask: "eyebrow_powder",
        defaultOpacity: 0.42
      }

    ]
  },
  "7": {
    isActive: false,
    image: Look_7,
    entities: [

      {
        idx: 0,
        sku_id: "BLENDER_0",
        sku_name: "BLENDER_0",
        type: "BLENDER",
        r: 232,
        g: 119,
        b: 204,
        a: 0.25,
        defaultOpacity: 0.25

      }
      ,
      {
        idx: 1,
        sku_id: "BLUSH_1",
        sku_name: "BLUSH_1",
        type: "BLUSH",
        r: 180,
        g: 117,
        b: 103,
        a: 0.1,
        shimmer_mask: "blush_shimmer",
        shimmer_intensity: 0,
        mask: "blush_2",
        defaultOpacity: 0.1
      }
      ,
      {
        idx: 1,
        sku_id: "LIPSTICK_1",
        sku_name: "LIPSTICK_1",
        type: "LIPSTICK",
        r: 165,
        g: 70,
        b: 40,
        a: 0.6588235294117647,
        finish_intensity: 1,
        finish_type: 2,
        glitter_mask: "lipGlitter_2",
        glitter_intensity: 0,
        shimmer_mask: "lipShimmer_1",
        shimmer_intensity: 0,
        defaultOpacity: 0.6588235294117647
      }
      ,
      {
        idx: 1,
        sku_id: "EYESHADOW_1",
        sku_name: "EYESHADOW_1",
        type: "EYESHADOW",
        r: 81,
        g: 49,
        b: 32,
        a: 0.5,
        shimmer_mask: "eyeshadow_shimmer",
        shimmer_intensity: 0,
        mask: "eyeshadow_1",
        defaultOpacity: 0.5
      }
      ,
      {
        idx: 0,
        sku_id: "EYELASH_0",
        sku_name: "EYELASH_0",
        type: "EYELASH",
        r: 25,
        g: 25,
        b: 25,
        a: 0.4,
        mask: "eyelash_7",
        defaultOpacity: 0.4
      }
      ,
      {
        idx: 0,
        sku_id: "EYELINER_0",
        sku_name: "EYELINER_0",
        type: "EYELINER",
        r: 77,
        g: 72,
        b: 66,
        a: 0.5490196078431373,
        mask: "eyeliner_4",
        defaultOpacity: 0.5490196078431373
      }
      ,
      {
        idx: 0,
        sku_id: "EYEBROW_0",
        sku_name: "EYEBROW_0",
        type: "EYEBROW",
        r: 136,
        g: 109,
        b: 88,
        a: 0.75,
        mask: "eyebrow_powder",
        defaultOpacity: 0.75
      }

    ]
  },
  "8": {
    isActive: false,
    image: Look_8,
    entities: [
      {
        idx: 0,
        sku_id: "FOUNDATION_0",
        sku_name: "FOUNDATION_0",
        type: "FOUNDATION",
        r: 244,
        g: 208,
        b: 142,
        a: 0.1,
        mask: "foundation_fullface",
        defaultOpacity: 0.1
      }
      ,
      {
        idx: 0,
        sku_id: "BLENDER_0",
        sku_name: "BLENDER_0",
        type: "BLENDER",
        r: 167,
        g: 126,
        b: 89,
        a: 0.5,
        defaultOpacity: 0.5
      }
      ,
      {
        idx: 0,
        sku_id: "BLUSH_0",
        sku_name: "BLUSH_0",
        type: "BLUSH",
        r: 169,
        g: 111,
        b: 75,
        a: 0.25,
        shimmer_mask: "blush_shimmer",
        shimmer_intensity: 0,
        mask: "blush_3",
        defaultOpacity: 0.25
      }
      ,
      {
        idx: 0,
        sku_id: "LIPLINER_0",
        sku_name: "LIPLINER_0",
        type: "LIPLINER",
        r: 171,
        g: 24,
        b: 51,
        a: 0.3,
        mask: "lipliner_1",
        defaultOpacity: 0.3
      }
      ,
      {
        idx: 0,
        sku_id: "CONTOUR_0",
        sku_name: "CONTOUR_0",
        type: "CONTOUR",
        r: 228,
        g: 211,
        b: 182,
        a: 0.25,
        mask: "bronzer_fullface_contour_3",
        defaultOpacity: 0.25
      }
      ,
      {
        idx: 0,
        sku_id: "LIPSTICK_0",
        sku_name: "LIPSTICK_0",
        type: "LIPSTICK",
        r: 171,
        g: 24,
        b: 51,
        a: 0.8,
        finish_intensity: 0.2,
        finish_type: 1,
        glitter_mask: "lipGlitter_2",
        glitter_intensity: 0,
        shimmer_mask: "lipShimmer_1",
        shimmer_intensity: 0,
        defaultOpacity: 0.8
      }
      ,
      {
        idx: 0,
        sku_id: "EYESHADOW_0",
        sku_name: "EYESHADOW_0",
        type: "EYESHADOW",
        r: 108,
        g: 74,
        b: 45,
        a: 1,
        shimmer_mask: "eyeshadow_shimmer",
        shimmer_intensity: 0,
        mask: "eyeshadow_4",
        defaultOpacity: 1
      }
      ,
      {
        idx: 0,
        sku_id: "EYELASH_0",
        sku_name: "EYELASH_0",
        type: "EYELASH",
        r: 25,
        g: 25,
        b: 25,
        a: 0.55,
        mask: "eyelash_8",
        defaultOpacity: 0.55
      }
      ,
      {
        idx: 0,
        sku_id: "EYELINER_0",
        sku_name: "EYELINER_0",
        type: "EYELINER",
        r: 33,
        g: 35,
        b: 35,
        a: 0.4,
        mask: "eyeliner_5",
        defaultOpacity: 0.4
      }
      ,
      {
        idx: 0,
        sku_id: "EYEBROW_0",
        sku_name: "EYEBROW_0",
        type: "EYEBROW",
        r: 114,
        g: 79,
        b: 65,
        a: 0.5,
        mask: "eyebrow_powder",
        defaultOpacity: 0.5
      }

    ]
  },
  "9": {
    isActive: false,
    image: Look_9,
    entities: [
      {
        idx: 0,
        sku_id: "FOUNDATION_0",
        sku_name: "FOUNDATION_0",
        type: "FOUNDATION",
        r: 237,
        g: 202,
        b: 171,
        a: 0.1,
        mask: "foundation_fullface",
        defaultOpacity: 0.1
      }
      ,
      {
        idx: 0,
        sku_id: "BLENDER_0",
        sku_name: "BLENDER_0",
        type: "BLENDER",
        r: 208,
        g: 153,
        b: 239,
        a: 0.15,
        defaultOpacity: 0.15
      }
      ,
      {
        idx: 0,
        sku_id: "BRONZER_0",
        sku_name: "BRONZER_0",
        type: "BRONZER",
        r: 162,
        g: 121,
        b: 83,
        a: 0.5019607843137255,
        mask: "bronzer_contour",
        defaultOpacity: 0.5019607843137255
      }
      ,
      {
        idx: 0,
        sku_id: "CONTOUR_0",
        sku_name: "CONTOUR_0",
        type: "CONTOUR",
        r: 231,
        g: 211,
        b: 188,
        a: 0.3,
        mask: "bronzer_fullface_contour_3",
        defaultOpacity: 0.3
      }
      ,
      {
        idx: 0,
        sku_id: "LIPSTICK_0",
        sku_name: "LIPSTICK_0",
        type: "LIPSTICK",
        r: 179,
        g: 35,
        b: 132,
        a: 0.8392156862745098,
        finish_intensity: 1,
        finish_type: 0,
        glitter_mask: "lipGlitter_2",
        glitter_intensity: 0,
        shimmer_mask: "lipShimmer_1",
        shimmer_intensity: 0.5,
        defaultOpacity: 0.8392156862745098
      }
      ,
      {
        idx: 1,
        sku_id: "EYESHADOW_1",
        sku_name: "EYESHADOW_1",
        type: "EYESHADOW",
        r: 235,
        g: 228,
        b: 232,
        a: 0.75,
        shimmer_mask: "eyeshadow_shimmer",
        shimmer_intensity: 0,
        mask: "eyeshadow_5",
        defaultOpacity: 0.75
      }
      ,
      {
        idx: 0,
        sku_id: "EYELASH_0",
        sku_name: "EYELASH_0",
        type: "EYELASH",
        r: 25,
        g: 25,
        b: 25,
        a: 0.6,
        mask: "eyelash_9",
        defaultOpacity: 0.6
      }
      ,
      {
        idx: 1,
        sku_id: "EYELINER_1",
        sku_name: "EYELINER_1",
        type: "EYELINER",
        r: 33,
        g: 35,
        b: 35,
        a: 0.45,
        mask: "eyeliner_6",
        defaultOpacity: 0.45
      }
      ,
      {
        idx: 0,
        sku_id: "EYEBROW_0",
        sku_name: "EYEBROW_0",
        type: "EYEBROW",
        r: 98,
        g: 75,
        b: 56,
        a: 0.7,
        mask: "eyebrow_powder_3",
        defaultOpacity: 0.7
      }

    ]
  },
  "10": {
    isActive: false,
    image: Look_10,
    entities: [
      {
        idx: 0,
        sku_id: "FOUNDATION_0",
        sku_name: "FOUNDATION_0",
        type: "FOUNDATION",
        r: 213,
        g: 155,
        b: 128,
        a: 0.2235294117647059,
        mask: "foundation_fullface",
        defaultOpacity: 0.2235294117647059
      }
      ,
      {
        idx: 0,
        sku_id: "BLUSH_0",
        sku_name: "BLUSH_0",
        type: "BLUSH",
        r: 213,
        g: 142,
        b: 114,
        a: 0.2,
        shimmer_mask: "blush_shimmer",
        shimmer_intensity: 0,
        mask: "blush_3",
        defaultOpacity: 0.2
      }
      ,
      {
        idx: 0,
        sku_id: "LIPLINER_0",
        sku_name: "LIPLINER_0",
        type: "LIPLINER",
        r: 200,
        g: 100,
        b: 75,
        a: 0.55,
        mask: "lipliner_3",
        defaultOpacity: 0.55
      }
      ,
      {
        idx: 1,
        sku_id: "CONTOUR_1",
        sku_name: "CONTOUR_1",
        type: "CONTOUR",
        r: 228,
        g: 211,
        b: 182,
        a: 0.2,
        mask: "bronzer_fullface_contour_3",
        defaultOpacity: 0.2
      }
      ,
      {
        idx: 1,
        sku_id: "LIPSTICK_1",
        sku_name: "LIPSTICK_1",
        type: "LIPSTICK",
        r: 180,
        g: 100,
        b: 100,
        a: 0.8784313725490196,
        finish_intensity: 1,
        finish_type: 0,
        glitter_mask: "lipGlitter_2",
        glitter_intensity: 0,
        shimmer_mask: "lipShimmer_1",
        shimmer_intensity: 0,
        defaultOpacity: 0.8784313725490196
      }
      ,
      {
        idx: 1,
        sku_id: "EYESHADOW_1",
        sku_name: "EYESHADOW_1",
        type: "EYESHADOW",
        r: 107,
        g: 74,
        b: 58,
        a: 0.6,
        shimmer_mask: "eyeshadow_shimmer",
        shimmer_intensity: 0,
        mask: "eyeshadow_20",
        defaultOpacity: 0.6
      }
      ,
      {
        idx: 0,
        sku_id: "EYELASH_0",
        sku_name: "EYELASH_0",
        type: "EYELASH",
        r: 25,
        g: 25,
        b: 25,
        a: 0.35,
        mask: "eyelash_10",
        defaultOpacity: 0.35
      }
      ,
      {
        idx: 0,
        sku_id: "EYELINER_0",
        sku_name: "EYELINER_0",
        type: "EYELINER",
        r: 35,
        g: 35,
        b: 35,
        a: 0.35,
        mask: "eyeliner_7",
        defaultOpacity: 0.35
      }
      ,
      {
        idx: 0,
        sku_id: "EYEBROW_0",
        sku_name: "EYEBROW_0",
        type: "EYEBROW",
        r: 132,
        g: 111,
        b: 94,
        a: 0.55,
        mask: "eyebrow_powder_5",
        defaultOpacity: 0.55
      }

    ]
  },
  "11": {
    isActive: false,
    image: Look_11,
    entities: [
      {
        idx: 0,
        sku_id: "BLENDER_0",
        sku_name: "BLENDER_0",
        type: "BLENDER",
        r: 241,
        g: 217,
        b: 206,
        a: 0.3,
        defaultOpacity: 0.3
      }
      ,
      {
        idx: 0,
        sku_id: "LIPLINER_0",
        sku_name: "LIPLINER_0",
        type: "LIPLINER",
        r: 181,
        g: 112,
        b: 96,
        a: 0.4392156862745098,
        mask: "lipliner_1",
        defaultOpacity: 0.4392156862745098
      }
      ,
      {
        idx: 0,
        sku_id: "LIPSTICK_0",
        sku_name: "LIPSTICK_0",
        type: "LIPSTICK",
        r: 111,
        g: 36,
        b: 36,
        a: 0.7490196078431373,
        finish_intensity: 0.3,
        finish_type: 1,
        glitter_mask: "lipGlitter_2",
        glitter_intensity: 0,
        shimmer_mask: "lipShimmer_1",
        shimmer_intensity: 0,
        defaultOpacity: 0.7490196078431373
      }
      , {
        idx: 0,
        sku_id: "EYESHADOW_0",
        sku_name: "EYESHADOW_0",
        type: "EYESHADOW",
        r: 88,
        g: 65,
        b: 55,
        a: 0.5490196078431373,
        shimmer_mask: "eyeshadow_shimmer",
        shimmer_intensity: 0,
        mask: "eyeshadow_10",
        defaultOpacity: 0.5490196078431373
      }
      ,
      {
        idx: 0,
        sku_id: "EYELINER_0",
        sku_name: "EYELINER_0",
        type: "EYELINER",
        r: 35,
        g: 35,
        b: 35,
        a: 0.55,
        mask: "eyeliner_8",
        defaultOpacity: 0.55
      }
      ,
      {
        idx: 0,
        sku_id: "EYEBROW_0",
        sku_name: "EYEBROW_0",
        type: "EYEBROW",
        r: 130,
        g: 100,
        b: 70,
        a: 0.8,
        mask: "eyebrow_powder",
        defaultOpacity: 0.8
      }

    ]
  },
  "12": {
    isActive: false,
    image: Look_12,
    entities: [
      {
        idx: 1,
        sku_id: "FOUNDATION_1",
        sku_name: "FOUNDATION_1",
        type: "FOUNDATION",
        r: 206,
        g: 132,
        b: 98,
        a: 0.2,
        mask: "foundation_fullface",
        defaultOpacity: 0.2
      }
      ,
      {
        idx: 1,
        sku_id: "BLUSH_1",
        sku_name: "BLUSH_1",
        type: "BLUSH",
        r: 235,
        g: 130,
        b: 88,
        a: 0.3,
        shimmer_mask: "blush_shimmer",
        shimmer_intensity: 0,
        mask: "blush_3",
        defaultOpacity: 0.3
      }
      ,
      {
        idx: 1,
        sku_id: "CONTOUR_1",
        sku_name: "CONTOUR_1",
        type: "CONTOUR",
        r: 220,
        g: 200,
        b: 188,
        a: 0.6,
        mask: "bronzer_fullface_contour_3",
        defaultOpacity: 0.6
      }
      ,
      {
        idx: 1,
        sku_id: "LIPSTICK_1",
        sku_name: "LIPSTICK_1",
        type: "LIPSTICK",
        r: 200,
        g: 10,
        b: 10,
        a: 0.9019607843137255,
        finish_intensity: 0.2,
        finish_type: 1,
        glitter_mask: "lipGlitter_2",
        glitter_intensity: 0,
        shimmer_mask: "lipShimmer_1",
        shimmer_intensity: 0.15,
        defaultOpacity: 0.9019607843137255
      }
      ,
      {
        idx: 0,
        sku_id: "EYESHADOW_0",
        sku_name: "EYESHADOW_0",
        type: "EYESHADOW",
        r: 155,
        g: 38,
        b: 38,
        a: 0.5,
        shimmer_mask: "eyeshadow_shimmer",
        shimmer_intensity: 0.2,
        mask: "eyeshadow_23",
        defaultOpacity: 0.5
      }
      ,
      {
        idx: 0,
        sku_id: "EYELASH_0",
        sku_name: "EYELASH_0",
        type: "EYELASH",
        r: 25,
        g: 25,
        b: 25,
        a: 0.4,
        mask: "eyelash_10",
        defaultOpacity: 0.4
      }
      ,
      {
        idx: 0,
        sku_id: "EYELINER_0",
        sku_name: "EYELINER_0",
        type: "EYELINER",
        r: 35,
        g: 35,
        b: 35,
        a: 0.55,
        mask: "eyeliner_9",
        defaultOpacity: 0.55
      }
      ,
      {
        idx: 0,
        sku_id: "EYEBROW_0",
        sku_name: "EYEBROW_0",
        type: "EYEBROW",
        r: 115,
        g: 55,
        b: 35,
        a: 0.55,
        mask: "eyebrow_powder_3",
        defaultOpacity: 0.55
      }
    ]
  },
  "13": {
    isActive: false,
    image: Look_13,
    entities: [
      {
        idx: 0,
        sku_id: "FOUNDATION_0",
        sku_name: "FOUNDATION_0",
        type: "FOUNDATION",
        r: 234,
        g: 195,
        b: 178,
        a: 0.1,
        mask: "foundation_fullface",
        defaultOpacity: 0.1
      }
      ,
      {
        idx: 0,
        sku_id: "BLUSH_0",
        sku_name: "BLUSH_0",
        type: "BLUSH",
        r: 222,
        g: 150,
        b: 120,
        a: 0.35,
        shimmer_mask: "blush_shimmer",
        shimmer_intensity: 0,
        mask: "blush_3",
        defaultOpacity: 0.35
      }
      ,
      {
        idx: 0,
        sku_id: "LIPLINER_0",
        sku_name: "LIPLINER_0",
        type: "LIPLINER",
        r: 230,
        g: 144,
        b: 127,
        a: 1,
        mask: "lipliner_2",
        defaultOpacity: 1
      }
      ,
      {
        idx: 0,
        sku_id: "CONTOUR_0",
        sku_name: "CONTOUR_0",
        type: "CONTOUR",
        r: 240,
        g: 200,
        b: 160,
        a: 0.44,
        mask: "bronzer_fullface_contour_3",
        defaultOpacity: 0.44
      }
      ,
      {
        idx: 0,
        sku_id: "LIPSTICK_0",
        sku_name: "LIPSTICK_0",
        type: "LIPSTICK",
        r: 135,
        g: 65,
        b: 85,
        a: 0.75,
        finish_intensity: 0.5,
        finish_type: 1,
        glitter_mask: "lipGlitter_2",
        glitter_intensity: 0,
        shimmer_mask: "lipShimmer_1",
        shimmer_intensity: 0.2,
        defaultOpacity: 0.75
      }
      ,
      {
        idx: 1,
        sku_id: "EYESHADOW_1",
        sku_name: "EYESHADOW_1",
        type: "EYESHADOW",
        r: 90,
        g: 45,
        b: 20,
        a: 0.55,
        shimmer_mask: "eyeshadow_shimmer",
        shimmer_intensity: 0,
        mask: "eyeshadow_25",
        defaultOpacity: 0.55
      }
      ,
      {
        idx: 0,
        sku_id: "EYELASH_0",
        sku_name: "EYELASH_0",
        type: "EYELASH",
        r: 25,
        g: 25,
        b: 25,
        a: 0.35,
        mask: "eyelash_11",
        defaultOpacity: 0.35
      }
      ,
      {
        idx: 0,
        sku_id: "EYEBROW_0",
        sku_name: "EYEBROW_0",
        type: "EYEBROW",
        r: 150,
        g: 125,
        b: 100,
        a: 0.88,
        mask: "eyebrow_powder_5",
        defaultOpacity: 0.88
      }
    ]
  },
  "14": {
    isActive: false,
    image: Look_14,
    entities: [
      {
        idx: 0,
        sku_id: "FOUNDATION_0",
        sku_name: "FOUNDATION_0",
        type: "FOUNDATION",
        r: 200,
        g: 150,
        b: 120,
        a: 0.2,
        mask: "foundation_fullface",
        defaultOpacity: 0.2
      }
      , {
        idx: 1,
        sku_id: "BLUSH_1",
        sku_name: "BLUSH_1",
        type: "BLUSH",
        r: 200,
        g: 120,
        b: 100,
        a: 0.35,
        shimmer_mask: "blush_shimmer",
        shimmer_intensity: 0,
        mask: "blush_2",
        defaultOpacity: 0.35
      }
      , {
        idx: 0,
        sku_id: "BRONZER_0",
        sku_name: "BRONZER_0",
        type: "BRONZER",
        r: 50,
        g: 40,
        b: 30,
        a: 0.4,
        mask: "bronzer_fullface",
        defaultOpacity: 0.4
      }
      ,
      {
        idx: 0,
        sku_id: "CONTOUR_0",
        sku_name: "CONTOUR_0",
        type: "CONTOUR",
        r: 225,
        g: 210,
        b: 180,
        a: 0.2196078431372549,
        mask: "bronzer_fullface_contour_3",
        defaultOpacity: 0.2196078431372549
      }
      , {
        idx: 0,
        sku_id: "LIPSTICK_0",
        sku_name: "LIPSTICK_0",
        type: "LIPSTICK",
        r: 111,
        g: 60,
        b: 50,
        a: 0.7,
        finish_intensity: 1,
        finish_type: 1,
        glitter_mask: "lipGlitter_2",
        glitter_intensity: 0,
        shimmer_mask: "lipShimmer_1",
        shimmer_intensity: 0,
        defaultOpacity: 0.7
      }
      , {
        idx: 0,
        sku_id: "EYESHADOW_0",
        sku_name: "EYESHADOW_0",
        type: "EYESHADOW",
        r: 200,
        g: 100,
        b: 88,
        a: 0.5,
        shimmer_mask: "eyeshadow_shimmer",
        shimmer_intensity: 0,
        mask: "eyeshadow_15",
        defaultOpacity: 0.5
      }
      , {
        idx: 0,
        sku_id: "EYELASH_0",
        sku_name: "EYELASH_0",
        type: "EYELASH",
        r: 25,
        g: 25,
        b: 25,
        a: 0.45,
        mask: "eyelash_12",
        defaultOpacity: 0.45
      }
      , {
        idx: 0,
        sku_id: "EYELINER_0",
        sku_name: "EYELINER_0",
        type: "EYELINER",
        r: 35,
        g: 35,
        b: 35,
        a: 0.45098039215686275,
        mask: "eyeliner_10",
        defaultOpacity: 0.45098039215686275
      }
      ,
      {
        idx: 0,
        sku_id: "EYEBROW_0",
        sku_name: "EYEBROW_0",
        type: "EYEBROW",
        r: 125,
        g: 100,
        b: 77,
        a: 0.66,
        mask: "eyebrow_powder_3",
        defaultOpacity: 0.66
      }

    ]
  },
  "15": {
    isActive: false,
    image: Look_15,
    entities: [
      {
        idx: 0,
        sku_id: "FOUNDATION_0",
        sku_name: "FOUNDATION_0",
        type: "FOUNDATION",
        r: 200,
        g: 160,
        b: 122,
        a: 0.2235294117647059,
        mask: "foundation_fullface",
        defaultOpacity: 0.2235294117647059
      }
      , {
        idx: 0,
        sku_id: "BLUSH_0",
        sku_name: "BLUSH_0",
        type: "BLUSH",
        r: 222,
        g: 150,
        b: 120,
        a: 0.5,
        shimmer_mask: "blush_shimmer",
        shimmer_intensity: 0.25,
        mask: "blush_3",
        defaultOpacity: 0.5
      }
      ,
      {
        idx: 0,
        sku_id: "HIGHLIGHTER_0",
        sku_name: "HIGHLIGHTER_0",
        type: "HIGHLIGHTER",
        r: 217,
        g: 180,
        b: 117,
        a: 0.15,
        shimmer_mask: "highlighter_shimmer",
        shimmer_intensity: 0.25,
        mask: "highlighter_1",
        defaultOpacity: 0.15
      }
      , {
        idx: 0,
        sku_id: "BRONZER_0",
        sku_name: "BRONZER_0",
        type: "BRONZER",
        r: 94,
        g: 78,
        b: 63,
        a: 0.55,
        mask: "bronzer_fullface",
        defaultOpacity: 0.55
      }
      , {
        idx: 0,
        sku_id: "CONTOUR_0",
        sku_name: "CONTOUR_0",
        type: "CONTOUR",
        r: 228,
        g: 211,
        b: 182,
        a: 0.3,
        mask: "bronzer_fullface_contour_3",
        defaultOpacity: 0.3
      }
      , {
        idx: 1,
        sku_id: "LIPSTICK_1",
        sku_name: "LIPSTICK_1",
        type: "LIPSTICK",
        r: 65,
        g: 5,
        b: 5,
        a: 0.8784313725490196,
        finish_intensity: 1,
        finish_type: 0,
        glitter_mask: "lipGlitter_2",
        glitter_intensity: 0,
        shimmer_mask: "lipShimmer_1",
        shimmer_intensity: 0.15,
        defaultOpacity: 0.8784313725490196
      }
      , {
        idx: 0,
        sku_id: "EYESHADOW_0",
        sku_name: "EYESHADOW_0",
        type: "EYESHADOW",
        r: 210,
        g: 150,
        b: 115,
        a: 0.6,
        shimmer_mask: "eyeshadow_shimmer",
        shimmer_intensity: 0.15,
        mask: "eyeshadow_15",
        defaultOpacity: 0.6
      }
      ,
      {
        idx: 0,
        sku_id: "EYELASH_0",
        sku_name: "EYELASH_0",
        type: "EYELASH",
        r: 25,
        g: 25,
        b: 25,
        a: 0.4,
        mask: "eyelash_13",
        defaultOpacity: 0.4
      }
      ,
      {
        idx: 0,
        sku_id: "EYELINER_0",
        sku_name: "EYELINER_0",
        type: "EYELINER",
        r: 35,
        g: 35,
        b: 35,
        a: 0.55,
        mask: "eyeliner_11",
        defaultOpacity: 0.55
      }
      ,
      {
        idx: 0,
        sku_id: "EYEBROW_0",
        sku_name: "EYEBROW_0",
        type: "EYEBROW",
        r: 122,
        g: 90,
        b: 70,
        a: 0.7,
        mask: "eyebrow_powder",
        defaultOpacity: 0.7
      }

    ]
  }
};
