/**
 * load vmt.js script into the document.
 * this script will automatically include vmt.wasm
 * and execute main() program
 */
document.write(
  "<script type='text/javascript' src='vmt.js?rd=" + Date.now() + "'></script>"
);

/**
 * vmt_module object is created as a consequence of above script.
 * through vmt_module you can access the heap and methods used by vmt.wasm.
 * we will now set standard output for vmt.wasm.
 */
window.vmt_module = {
  print: function(text) {
    console.log("[INFO] " + text);
    // window.SERVICES.vmtlog.registerInfo(text);
  },
  printErr: function(text) {
    console.log("[ERR] " + text);
    // window.SERVICES.vmtlog.registerErr(text);
  }
};

var vmt_module = window.vmt_module;

/**
 * define vmt core object globally so we can use it in our developer console.
 */
var vmt_core = undefined;

/* wait until the vmt_module is fully loaded */
document.addEventListener("vmt_is_ready", e => loadApplication(e));
var interval = setInterval(() => {
  try {
    if (vmt_module._isVmtReady()) {
      /* dispatch vmt_is_ready */
      document.dispatchEvent(new CustomEvent("vmt_is_ready"));
      document.dispatchEvent(new CustomEvent("vmtIsReady"));
      clearInterval(interval);
    }
  } catch (e) {
    console.debug("[DEBUG] vmt is not ready, retrying ...");
  }
}, 50);

/**
 * this function will load VMTCore Class/Object, and binds several buttons
 * in the application with appropriate procedures.
 */
function loadApplication(evt) {
  /**
   * ##############################################
   *  Helpers
   * ##############################################
   * run landmark detection on image from input canvas
   */

  const _freeArray = heapBytes => {
    try {
      vmt_module._free(heapBytes.byteOffset);
    } catch (e) {
      console.error("[ERR] _freeArray err = ", e);
    }
  };

  const _freeString = buffer => {
    try {
      vmt_module._free(buffer);
    } catch (e) {
      console.error("[ERR] _freeString  err = ", e);
    }
  };

  const _arrayToHeap = typedArray => {
    try {
      let numBytes = typedArray.length * typedArray.BYTES_PER_ELEMENT;
      let ptr = vmt_module._malloc(numBytes);
      let heapBytes = vmt_module.HEAPU8.subarray(ptr, ptr + numBytes);
      heapBytes.set(typedArray);
      return heapBytes;
    } catch (e) {
      console.error("[ERR] _freeAToHeap  err = ", e);
      return undefined;
    }
  };

  const _stringToHeap = str => {
    try {
      let length = vmt_module.lengthBytesUTF8(str);
      let buffer = vmt_module._malloc(length + 1);
      vmt_module.stringToUTF8(str, buffer, length + 1);
      return buffer;
    } catch (e) {
      console.error("[ERR] _stringToHeap  err = ", e);
      return undefined;
    }
  };

  const _getRandColors = () => {
    let obj = {};
    let min = 0;
    let max = 255;
    obj.r = Math.floor(Math.random() * (max - min + 1)) + min;
    obj.g = Math.floor(Math.random() * (max - min + 1)) + min;
    obj.b = Math.floor(Math.random() * (max - min + 1)) + min;
    obj.a = Math.random();
    obj.gl = Math.random();
    return obj;
  };

  /**
   * VMTCore is a js class which provides the developer/integrator with a
   * rich api and conceals the complexity associated with vmt_module.
   * 1. choose a input canvas and output canvas,
   * 2. draw a picture on input canvas and call the getDetection(), will detect landmarks and head pose
   * 3. now set a makeup element, see applyRandomFoundation for example
   * 4. then call render()
   * 5. the image on input canvas with applied makeup that you choose will be rendered on output canvas
   * Note: free free to change this api to suit your needs.
   */
  function VMTCore() {}

  VMTCore.prototype.init = function(input_canvas_id, output_canvas_id) {
    this.canvas_i = document.getElementById(input_canvas_id);
    this.canvas_o = document.getElementById(output_canvas_id);
    this.canvas_s = document.createElement("canvas");
    this.canvas_s.setAttribute("id", "canvas_staging");
    this.canvas_splitter = document.getElementById(this.splitter_canvas_id);
    this.ctx_i = this.canvas_i.getContext("2d");
    this.ctx_o = this.canvas_o.getContext("2d");
    this.ctx_s = this.canvas_s.getContext("2d");
    this.ctx_splitter = this.canvas_splitter.getContext("2d");
    this.width = this.canvas_i.width;
    this.height = this.canvas_i.height;
    this.canvas_s.width = this.width;
    this.canvas_s.height = this.height;
    this.canvas_o.width = this.width;
    this.canvas_o.height = this.height;
    this.canvas_splitter.width = this.width;
    this.canvas_splitter.height = this.height;
    this.detected = false;
    this.image_data = undefined;
    this.frame_bytes = undefined;
    this.zoom_factor = 1;
    this.zoomUpdated = false;
    this.isSplitterActive = false;
    this.isLive = false;
    this.rotations_made = 0;
    this.alpha_factor = 2;
    this.allowed_render_fail_cnts = 4;

    document.dispatchEvent(new Event("canvasRendered"));
  };

  VMTCore.prototype.getIsLive = function() {
    return this.isLive;
  };
  VMTCore.prototype.getIsSplitterActive = function() {
    return this.isSplitterActive;
  };
  VMTCore.prototype.getIsDetected = function() {
    return this.detected;
  };
  VMTCore.prototype.setIsLive = function(value) {
    this.isLive = value;
  };
  VMTCore.prototype.setIsSplitterActive = function(value) {
    this.isSplitterActive = value;
  };
  VMTCore.prototype.getSplitterImageB64 = function() {
    return this.canvas_splitter.toDataURL();
  };
  VMTCore.prototype.getBaseImageB64 = function() {
    return this.canvas_i.toDataURL();
  };
  VMTCore.prototype.getFinalCanvas = function() {
    return this.canvas_o;
  };
  VMTCore.prototype.getInitialCanvas = function() {
    return this.canvas_i;
  };
  VMTCore.prototype.setAlphaFactor = function(value) {
    this.alpha_factor = value;
  };
  VMTCore.prototype.setSplitterCanvasId = function(splitter_canvas_id) {
    this.splitter_canvas_id = splitter_canvas_id;
  };

  /**
   * ##############################################
   *  LANDMARK DETECTION
   * ##############################################
   * run landmark detection on image from input canvas
   */
  VMTCore.prototype.getDetection = function() {
    // get image as buffer from input canvas
    this.img_data = this.ctx_i.getImageData(0, 0, this.width, this.height);
    // store image data in wasm's heap memory
    if (!this.frame_bytes) {
      this.frame_bytes = _arrayToHeap(this.img_data.data);
    } else if (this.frame_bytes.length !== this.img_data.data.length) {
      _freeArray(this.frame_bytes);
      this.frame_bytes = _arrayToHeap(this.img_data.data);
    } else {
      _freeArray(this.frame_bytes);
      this.frame_bytes = _arrayToHeap(this.img_data.data);
    }
    // submit the pointer to image data along with width and height and perform landmark detection
    this.detected = vmt_module._landmarkDetection(
      this.width,
      this.height,
      this.frame_bytes.byteOffset
    );

    // adjust for rotation,
    if (this.detected) {
      this.rotations_made = vmt_module._getRotationsMade();
    }
  };

  /**
   * ##############################################
   *  FOUNDATION
   * ##############################################
   */

  /**
   * applies random foundation to a copy of image from input canvas
   * with random color and opacity; draw this image on output canvas
   */
  VMTCore.prototype.applyRandomFoundation = function() {
    if (this.detected || this.isLive) {
      let clr = _getRandColors();
      vmt_module._setFoundation(clr.r, clr.g, clr.b, clr.a * this.alpha_factor);
    }
    if (!this.isLive) this.render();
  };

  /**
   * applies custom foundation to a copy of image from input canvas
   * with custom color and opacity; draw this image on output canvas
   */
  VMTCore.prototype.applyFoundation = function(r, g, b, a, mask = undefined) {
    if (this.detected || this.isLive) {
      if (mask != undefined) {
        let heapStr = _stringToHeap(mask);
        vmt_module._setPerfector(heapStr, r, g, b, a * this.alpha_factor);
      } else {
        vmt_module._setFoundation(r, g, b, a * this.alpha_factor);
      }
    }
    if (!this.isLive) this.render();
  };

  /**
   * removes foundation to a copy of image from input canvas
   * and draw this image on output canvas
   */
  VMTCore.prototype.removeFoundation = function() {
    if (this.detected || this.isLive) {
      vmt_module._resetFoundation();
    }
    if (!this.isLive) this.render();
  };

  /**
   * ##############################################
   *  BLENDER
   * ##############################################
   */

  /**
   * applies random blender to a copy of image from input canvas
   * with random color and opacity; draw this image on output canvas
   */
  VMTCore.prototype.applyRandomBlender = function() {
    if (this.detected || this.isLive) {
      let clr = _getRandColors();
      vmt_module._setBlender(clr.r, clr.g, clr.b, clr.a * this.alpha_factor);
    }
    if (!this.isLive) this.render();
  };

  /**
   * applies custom blender to a copy of image from input canvas
   * with custom color and opacity; draw this image on output canvas
   */
  VMTCore.prototype.applyBlender = function(r, g, b, a) {
    if (this.detected || this.isLive) {
      vmt_module._setBlender(r, g, b, a * this.alpha_factor);
    }
    if (!this.isLive) this.render();
  };

  /**
   * removes blender to a copy of image from input canvas
   * and draw this image on output canvas
   */
  VMTCore.prototype.removeBlender = function() {
    if (this.detected || this.isLive) {
      vmt_module._resetBlender();
    }
    if (!this.isLive) this.render();
  };

  /**
   * ##############################################
   *  BLUSH
   * ##############################################
   */

  /**
   * applies random blush to a copy of image from input canvas
   * with random color and opacity; draw this image on output canvas
   */
  VMTCore.prototype.applyRandomBlush = function() {
    if (this.detected || this.isLive) {
      let clr = _getRandColors();
      let heapStr = _stringToHeap("blush_1");
      vmt_module._setBlush(
        heapStr,
        clr.r,
        clr.g,
        clr.b,
        clr.a * this.alpha_factor
      );
      _freeString(heapStr);
    }
    if (!this.isLive) this.render();
  };

  /**
   * applies custom blush to a copy of image from input canvas
   * with custom color and opacity; draw this image on output canvas
   */
  VMTCore.prototype.applyBlush = function(
    mask,
    r,
    g,
    b,
    a,
    shmr_mask = null,
    shmr_intensity = null
  ) {
    if (this.detected || this.isLive) {
      if (shmr_mask && shmr_intensity)
        vmt_module._setBlushShimmerEffect(
          _stringToHeap(shmr_mask),
          shmr_intensity
        );
      let heapStr = _stringToHeap(mask);
      vmt_module._setBlush(heapStr, r, g, b, a * this.alpha_factor);
      _freeString(heapStr);
    }
    if (!this.isLive) this.render();
  };

  /**
   * removes blush to a copy of image from input canvas
   * and draw this image on output canvas
   */
  VMTCore.prototype.removeBlush = function() {
    if (this.detected || this.isLive) {
      vmt_module._resetBlush();
    }
    if (!this.isLive) this.render();
  };

  /**
   * ##############################################
   *  LIPSTICK
   * ##############################################
   */

  /**
   * applies random lipstick to a copy of image from input canvas
   * with random color and opacity; draw this image on output canvas
   */
  VMTCore.prototype.applyRandomLipstick = function() {
    if (this.detected || this.isLive) {
      let clr = _getRandColors();
      vmt_module._setLipstick(
        clr.r,
        clr.g,
        clr.b,
        clr.a * this.alpha_factor,
        clr.gl,
        0
      );
    }
    if (!this.isLive) this.render();
  };

  /**
   * applies custom lipstick to a copy of image from input canvas
   * with custom color and opacity; draw this image on output canvas
   */
  VMTCore.prototype.applyLipstick = function(
    r,
    g,
    b,
    a,
    fnsh_intensity,
    fnsh_type,
    gltr_mask = null,
    gltr_intensity = null,
    shmr_mask = null,
    shmr_intensity = null
  ) {
    if (this.detected || this.isLive) {
      if (gltr_mask && gltr_intensity)
        vmt_module._setLipGlitterEffect(
          _stringToHeap(gltr_mask),
          gltr_intensity
        );
      if (shmr_mask && shmr_intensity)
        vmt_module._setLipShimmerEffect(
          _stringToHeap(shmr_mask),
          shmr_intensity
        );
      vmt_module._setLipstick(r, g, b, a, fnsh_intensity, fnsh_type);
    }
    if (!this.isLive) this.render();
  };

  /**
   * removes lipstick to a copy of image from input canvas
   * and draw this image on output canvas
   */
  VMTCore.prototype.removeLipstick = function() {
    if (this.detected || this.isLive) {
      vmt_module._resetLipstick();
    }
    if (!this.isLive) this.render();
  };
  /**
   * ##############################################
   *  LIPLINER
   * ##############################################
   */

  /**
   * applies random lipliner to a copy of image from input canvas
   * with random color and opacity; draw this image on output canvas
   */
  VMTCore.prototype.applyRandomLipliner = function() {
    if (this.detected || this.isLive) {
      let clr = _getRandColors();
      let heapStr = _stringToHeap("lipliner_1");
      vmt_module._setLipliner(
        heapStr,
        clr.r,
        clr.g,
        clr.b,
        clr.a * this.alpha_factor
      );
      _freeString(heapStr);
    }
    if (!this.isLive) this.render();
  };

  /**
   * applies custom lipliner to a copy of image from input canvas
   * with custom color and opacity; draw this image on output canvas
   */
  VMTCore.prototype.applyLipliner = function(mask, r, g, b, a) {
    if (this.detected || this.isLive) {
      let heapStr = _stringToHeap(mask);
      vmt_module._setLipliner(heapStr, r, g, b, a * this.alpha_factor);
      _freeString(heapStr);
    }
    if (!this.isLive) this.render();
  };

  /**
   * removes lipliner to a copy of image from input canvas
   * and draw this image on output canvas
   */
  VMTCore.prototype.removeLipliner = function() {
    if (this.detected || this.isLive) {
      vmt_module._resetLipliner();
    }
    if (!this.isLive) this.render();
  };

  /**
   * ##############################################
   *  HIGHLIGHTER
   * ##############################################
   */

  /**
   * applies random highlighter to a copy of image from input canvas
   * with random color and opacity; draw this image on output canvas
   */
  VMTCore.prototype.applyRandomHighlighter = function() {
    if (this.detected || this.isLive) {
      let clr = _getRandColors();
      let heapStr = _stringToHeap("highlighter_1");
      vmt_module._setHighlighter(
        heapStr,
        clr.r,
        clr.g,
        clr.b,
        clr.a * this.alpha_factor
      );
      _freeString(heapStr);
    }
    if (!this.isLive) this.render();
  };

  /**
   * applies custom highlighter to a copy of image from input canvas
   * with custom color and opacity; draw this image on output canvas
   */
  VMTCore.prototype.applyHighlighter = function(
    mask,
    r,
    g,
    b,
    a,
    shmr_mask = null,
    shmr_intensity = null
  ) {
    if (this.detected || this.isLive) {
      if (shmr_mask && shmr_intensity)
        vmt_module._setHighlighterShimmerEffect(
          _stringToHeap(shmr_mask),
          shmr_intensity
        );
      let heapStr = _stringToHeap(mask);
      vmt_module._setHighlighter(heapStr, r, g, b, a * this.alpha_factor);
      _freeString(heapStr);
    }
    if (!this.isLive) this.render();
  };

  /**
   * removes highlighter to a copy of image from input canvas
   * and draw this image on output canvas
   */
  VMTCore.prototype.removeHighlighter = function() {
    if (this.detected || this.isLive) {
      vmt_module._resetHighlighter();
    }
    if (!this.isLive) this.render();
  };

  /**
   * ##############################################
   *  CONTOUR
   * ##############################################
   */

  /**
   * applies random contour to a copy of image from input canvas
   * with random color and opacity; draw this image on output canvas
   */
  VMTCore.prototype.applyRandomContour = function() {
    if (this.detected || this.isLive) {
      let clr = _getRandColors();
      let heapStr = _stringToHeap("bronzer_contour");
      vmt_module._setContour(
        heapStr,
        clr.r,
        clr.g,
        clr.b,
        clr.a * this.alpha_factor
      );
      _freeString(heapStr);
    }
    if (!this.isLive) this.render();
  };

  /**
   * applies custom contour to a copy of image from input canvas
   * with custom color and opacity; draw this image on output canvas
   */
  VMTCore.prototype.applyContour = function(mask, r, g, b, a) {
    if (this.detected || this.isLive) {
      let heapStr = _stringToHeap(mask);
      vmt_module._setContour(heapStr, r, g, b, a * this.alpha_factor);
      _freeString(heapStr);
    }
    if (!this.isLive) this.render();
  };

  /**
   * removes contour to a copy of image from input canvas
   * and draw this image on output canvas
   */
  VMTCore.prototype.removeContour = function() {
    if (this.detected || this.isLive) {
      vmt_module._resetContour();
    }
    if (!this.isLive) this.render();
  };

  /**
   * ##############################################
   *  BRONZER
   * ##############################################
   */

  /**
   * applies random bronzer to a copy of image from input canvas
   * with random color and opacity; draw this image on output canvas
   */
  VMTCore.prototype.applyRandomBronzer = function() {
    if (this.detected || this.isLive) {
      let clr = _getRandColors();
      let heapStr = _stringToHeap("bronzer_contour");
      vmt_module._setBronzer(
        heapStr,
        clr.r,
        clr.g,
        clr.b,
        clr.a * this.alpha_factor
      );
      _freeString(heapStr);
    }
    if (!this.isLive) this.render();
  };

  /**
   * applies custom bronzer to a copy of image from input canvas
   * with custom color and opacity; draw this image on output canvas
   */
  VMTCore.prototype.applyBronzer = function(mask, r, g, b, a) {
    if (this.detected || this.isLive) {
      let heapStr = _stringToHeap(mask);
      vmt_module._setBronzer(heapStr, r, g, b, a * this.alpha_factor);
      _freeString(heapStr);
    }
    if (!this.isLive) this.render();
  };

  /**
   * removes bronzer to a copy of image from input canvas
   * and draw this image on output canvas
   */
  VMTCore.prototype.removeBronzer = function() {
    if (this.detected || this.isLive) {
      vmt_module._resetBronzer();
    }
    if (!this.isLive) this.render();
  };

  /**
   * ##############################################
   *  EYEBROWS
   * ##############################################
   */

  /**
   * applies random eyebrows to a copy of image from input canvas
   * with random color and opacity; draw this image on output canvas
   */
  VMTCore.prototype.applyRandomEyebrow = function() {
    if (this.detected || this.isLive) {
      let clr = _getRandColors();
      let heapStr = _stringToHeap("eyebrow_powder");
      vmt_module._setEyebrow(
        heapStr,
        clr.r,
        clr.g,
        clr.b,
        clr.a * this.alpha_factor
      );
      _freeString(heapStr);
    }
    if (!this.isLive) this.render();
  };

  /**
   * applies custom eyebrow to a copy of image from input canvas
   * with custom color and opacity; draw this image on output canvas
   */
  VMTCore.prototype.applyEyebrow = function(mask, r, g, b, a) {
    if (this.detected || this.isLive) {
      let heapStr = _stringToHeap(mask);
      vmt_module._setEyebrow(heapStr, r, g, b, a * this.alpha_factor);
      _freeString(heapStr);
    }
    if (!this.isLive) this.render();
  };

  /**
   * removes eyebrow to a copy of image from input canvas
   * and draw this image on output canvas
   */
  VMTCore.prototype.removeEyebrow = function() {
    if (this.detected || this.isLive) {
      vmt_module._resetEyebrow();
    }
    if (!this.isLive) this.render();
  };

  /**
   * ##############################################
   *  EYESHADOW
   * ##############################################
   */

  /**
   * applies random eyeshadow to a copy of image from input canvas
   * with random color and opacity; draw this image on output canvas
   */
  VMTCore.prototype.applyRandomEyeshadow = function() {
    if (this.detected || this.isLive) {
      let clr = _getRandColors();
      let heapStr = _stringToHeap("eyeshadow_1");
      vmt_module._setEyeshadow(
        heapStr,
        clr.r,
        clr.g,
        clr.b,
        clr.a * this.alpha_factor
      );
      _freeString(heapStr);
    }
    if (!this.isLive) this.render();
  };

  /**
   * applies custom eyeshadow to a copy of image from input canvas
   * with custom color and opacity; draw this image on output canvas
   */
  VMTCore.prototype.applyEyeshadow = function(
    mask,
    r,
    g,
    b,
    a,
    shmr_mask = null,
    shmr_intensity = null
  ) {
    if (this.detected || this.isLive) {
      if (shmr_mask && shmr_intensity)
        vmt_module._setEyeshadowShimmerEffect(
          _stringToHeap(shmr_mask),
          shmr_intensity
        );
      let heapStr = _stringToHeap(mask);
      vmt_module._setEyeshadow(heapStr, r, g, b, a * this.alpha_factor);
      _freeString(heapStr);
    }
    if (!this.isLive) this.render();
  };

  /**
   * removes eyeshadow to a copy of image from input canvas
   * and draw this image on output canvas
   */
  VMTCore.prototype.removeEyeshadow = function() {
    if (this.detected || this.isLive) {
      vmt_module._resetEyeshadow();
    }
    if (!this.isLive) this.render();
  };

  /**
   * ##############################################
   *  EYELASH
   * ##############################################
   */

  /**
   * applies random eyelash to a copy of image from input canvas
   * with random color and opacity; draw this image on output canvas
   */
  VMTCore.prototype.applyRandomEyelash = function() {
    if (this.detected || this.isLive) {
      let clr = _getRandColors();
      let heapStr = _stringToHeap("eyelash_1");
      vmt_module._setEyelash(
        heapStr,
        clr.r,
        clr.g,
        clr.b,
        clr.a * this.alpha_factor
      );
      _freeString(heapStr);
    }
    if (!this.isLive) this.render();
  };

  /**
   * applies custom eyelash to a copy of image from input canvas
   * with custom color and opacity; draw this image on output canvas
   */
  VMTCore.prototype.applyEyelash = function(mask, r, g, b, a) {
    if (this.detected || this.isLive) {
      let heapStr = _stringToHeap(mask);
      vmt_module._setEyelash(heapStr, r, g, b, a * this.alpha_factor);
      _freeString(heapStr);
    }
    if (!this.isLive) this.render();
  };

  /**
   * removes eyelash to a copy of image from input canvas
   * and draw this image on output canvas
   */
  VMTCore.prototype.removeEyelash = function() {
    if (this.detected || this.isLive) {
      vmt_module._resetEyelash();
    }
    if (!this.isLive) this.render();
  };

  /**
   * ##############################################
   *  EYELINER
   * ##############################################
   */

  /**
   * applies random eyeliner to a copy of image from input canvas
   * with random color and opacity; draw this image on output canvas
   */
  VMTCore.prototype.applyRandomEyeliner = function() {
    if (this.detected || this.isLive) {
      let clr = _getRandColors();
      let heapStr = _stringToHeap("eyeliner_1");
      vmt_module._setEyeliner(
        heapStr,
        clr.r,
        clr.g,
        clr.b,
        clr.a * this.alpha_factor
      );
      _freeString(heapStr);
    }
    if (!this.isLive) this.render();
  };

  /**
   * applies custom eyeliner to a copy of image from input canvas
   * with custom color and opacity; draw this image on output canvas
   */
  VMTCore.prototype.applyEyeliner = function(mask, r, g, b, a) {
    if (this.detected || this.isLive) {
      let heapStr = _stringToHeap(mask);
      vmt_module._setEyeliner(heapStr, r, g, b, a * this.alpha_factor);
      _freeString(heapStr);
    }
    if (!this.isLive) this.render();
  };

  /**
   * removes eyeliner to a copy of image from input canvas
   * and draw this image on output canvas
   */
  VMTCore.prototype.removeEyeliner = function() {
    if (this.detected || this.isLive) {
      vmt_module._resetEyeliner();
    }
    if (!this.isLive) this.render();
  };

  /**
   * ##############################################
   *  RENDER & REFRESH & RESET DETECTION
   * ##############################################
   */

  VMTCore.prototype.zoomTo = function(val) {
    this.zoom_factor = Math.pow(1.1, val);
    this.zoomUpdated = true;
  };

  VMTCore.prototype.zoomLiveRoutine = function(val) {
    this.ctx_i.setTransform(1, 0, 0, 1, 0, 0);
    this.ctx_i.translate(this.canvas_i.width, 0);
    this.ctx_i.scale(-1, 1);
    this.ctx_i.translate(this.canvas_i.width / 2, this.canvas_i.height / 2);
    this.ctx_i.scale(this.zoom_factor, this.zoom_factor);
    this.ctx_i.translate(-this.canvas_i.width / 2, -this.canvas_i.height / 2);
  };

  /**
   * renders makeup and sets the frames_bytes object with rendered image information.
   * this information will used to draw on output canvas.
   */
  VMTCore.prototype.render = function() {
    try {
      this.refresh();
      if (this.detected || this.isLive) {
        vmt_module._renderMakeup(
          this.width,
          this.height,
          this.frame_bytes.byteOffset
        );
      }

      this.img_data.data.set(this.frame_bytes);
      this.ctx_s.putImageData(this.img_data, 0, 0);

      if (this.zoomUpdated || this.setIsSplitterActive) {
        /** zoom correction on output canvas */
        this.ctx_o.setTransform(1, 0, 0, 1, 0, 0);
        this.ctx_o.translate(this.canvas_o.width / 2, this.canvas_o.height / 2);
        this.ctx_o.scale(this.zoom_factor, this.zoom_factor);
        this.ctx_o.translate(
          -this.canvas_o.width / 2,
          -this.canvas_o.height / 2
        );

        /** zoom correction on splitter canvas */
        this.ctx_splitter.setTransform(1, 0, 0, 1, 0, 0);
        this.ctx_splitter.translate(
          this.canvas_o.width / 2,
          this.canvas_o.height / 2
        );
        this.ctx_splitter.scale(this.zoom_factor, this.zoom_factor);
        this.ctx_splitter.translate(
          -this.canvas_o.width / 2,
          -this.canvas_o.height / 2
        );
        this.ctx_splitter.drawImage(
          this.canvas_i,
          0,
          0,
          this.canvas_o.width,
          this.canvas_o.height
        );

        this.zoomUpdated = false;
      }

      this.ctx_o.drawImage(this.canvas_s, 0, 0);
    } catch (err) {
      if (this.allowed_render_fail_cnts <= 0) {
        console.error("rendering failed err=", err);
        this.allowed_render_fail_cnts = 4;
      } else {
        this.render();
        this.allowed_render_fail_cnts -= 1;
      }
      // window.SERVICES.notify("Unknown Rendering Error, Please repeat your last action again!","WARN")
    }
  };

  VMTCore.prototype.renderLive = function() {
    try {
      // get image as buffer from input canvas
      this.img_data = this.ctx_i.getImageData(0, 0, this.width, this.height);
      // store image data in wasm's heap memory
      if (!this.frame_bytes) {
        this.frame_bytes = _arrayToHeap(this.img_data.data);
      } else if (this.frame_bytes.length !== this.img_data.data.length) {
        _freeArray(this.frame_bytes);
        this.frame_bytes = _arrayToHeap(this.img_data.data);
      } else {
        _freeArray(this.frame_bytes);
        this.frame_bytes = _arrayToHeap(this.img_data.data);
      }

      if (this.isSplitterActive) {
        this.ctx_splitter.clearRect(0, 0, this.width, this.height);
        this.ctx_splitter.putImageData(this.img_data, 0, 0);
      }

      vmt_module._landmarkDetectionFullLifeCycle(
        this.width,
        this.height,
        this.frame_bytes.byteOffset,
        this.frame_bytes.byteOffset
      );
      this.img_data.data.set(this.frame_bytes);

      this.ctx_o.clearRect(0, 0, this.width, this.height);
      this.ctx_o.putImageData(this.img_data, 0, 0);
    } catch (err) {
      console.warn("camera loading problem. err=", err);
    }
  };

  /**
   * Usually image_data and frame_bytes will have information from previous render,
   * so it is essential to reset them with input image data before every render
   * the render() function automatically does this for you.
   */
  VMTCore.prototype.refresh = function() {
    this.img_data = this.ctx_i.getImageData(0, 0, this.width, this.height);
    if (!this.frame_bytes) {
      this.frame_bytes = _arrayToHeap(this.img_data.data);
    } else if (this.frame_bytes.length !== this.img_data.data.length) {
      _freeArray(this.frame_bytes);
      this.frame_bytes = _arrayToHeap(this.img_data.data);
    } else {
      _freeArray(this.frame_bytes);
      this.frame_bytes = _arrayToHeap(this.img_data.data);
    }
  };

  /**
   * Resets the detection flag, ignores the tracker and re-detects the face as if
   * it is seeing it for the first time.
   * Note: resetDetection should be applied just before a new image is processed
   * for landmarks
   * Note: for a video stream it is not the case, you should reset detection once
   * in the beginning of every stream. Also remember to set max iterations to 1 using
   * vm_module._setMaxIterations(1)
   */
  VMTCore.prototype.resetDetection = function() {
    vmt_module._resetDetection();
  };

  if (!window.SERVICES) {
    window.SERVICES = {};
  }

  window.SERVICES.vmtcoreClass = VMTCore;
  window.SERVICES.vmtcore = new VMTCore();
  document.dispatchEvent(new CustomEvent("vmtcore_loaded"));
}
