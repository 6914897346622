import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Store } from "redux";
import { FormattedMessage } from "react-intl";
import { Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

import { ROUTES } from "utils/constants";
import { ActionType, ModelDataType } from "types/common";
import {
  putCurrentModel,
  selectIsAnalyticRequired,
  selectModelScreenBackButton,
  selectModelScreenTitle,
  selectModelsImages
} from "ducks/app";
import { initImpression } from "ducks/analytics";

import styles from "./ModelPageContent.module.scss";

interface Props extends RouteComponentProps {
  isAnalyticRequired: boolean;
  modelScreenTitle: string;
  modelScreenBackButton: string;
  models: ModelDataType[];
  putCurrentModel: (model: ModelDataType) => ActionType;
  initImpression: (payload: string) => ActionType;
}

function ModelPageContent(props: Props) {
  const { modelScreenTitle, modelScreenBackButton } = props;

  const handleModelClick = (value: ModelDataType) => () => {
    props.putCurrentModel(value);
    props.history.push(ROUTES.tryOn);
    if (props.isAnalyticRequired) {
      props.initImpression("MODEL");
    }
  };

  const renderModels = () => {
    return props.models.map(model => (
      <div
        className={styles.modelContainer}
        key={model.value}
        onClick={handleModelClick(model)}
      >
        <div className={styles.model}>
          <img
            className={styles.modelImage}
            src={model.modelImage}
            alt="model"
          />
        </div>
      </div>
    ));
  };

  return (
    <div className={styles.root}>
      {!!modelScreenTitle && (
        <div className={styles.modelsTitle}>
          <FormattedMessage id={modelScreenTitle} />
        </div>
      )}
      <div className={styles.modelsContainer}>{renderModels()}</div>
      <Link to={ROUTES.root} className={styles.backBtn}>
        <FormattedMessage id={modelScreenBackButton} /> <Icon name="close" />
      </Link>
    </div>
  );
}

const mapStateToProps = (state: Store) => ({
  isAnalyticRequired: selectIsAnalyticRequired(state),
  modelScreenTitle: selectModelScreenTitle(state),
  modelScreenBackButton: selectModelScreenBackButton(state),
  models: selectModelsImages(state)
});

const mapDispatchToProps = {
  putCurrentModel,
  initImpression
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModelPageContent)
);
