import { SagaIterator } from "redux-saga";
import { spawn } from "redux-saga/effects";

import { saga as appSaga } from "ducks/app";
import { saga as analyticsSaga } from "ducks/analytics";

export default function* rootSaga(): SagaIterator {
  yield spawn(appSaga);
  yield spawn(analyticsSaga);
}
