import { MakeUpDataType, OptionsList } from "types/common";
import { transmitEvent } from "ducks/analytics";

export const arrayOfObjectsToOptionList = (
  arr: Array<{ [key: string]: any }>,
  textKey: string,
  valueKey: string
): OptionsList[] =>
  arr.map(
    (item: { [key: string]: string }): OptionsList => ({
      text: item[textKey],
      value: item[valueKey]
    })
  );

export const generateUUID = () => {
  let time = new Date().getTime();
  const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, char => {
    // tslint:disable-next-line:no-bitwise
    const randomTime = (time + Math.random() * 16) % 16 | 0;
    time = Math.floor(time / 16);
    // tslint:disable-next-line:no-bitwise
    return (char === "x" ? randomTime : (randomTime & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

export const generateOID = () => {
  const length = 16;
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const getStandardTimeStamp = () => new Date().toISOString();

export const setImpressionType = (impressionType: string) => {
  sessionStorage.setItem(
    "isLive",
    impressionType === "LIVE" ? "true" : "false"
  );
  sessionStorage.setItem(
    "isSelfie",
    impressionType === "SELFIE" ? "true" : "false"
  );
  sessionStorage.setItem(
    "isModel",
    impressionType === "MODEL" ? "true" : "false"
  );
};

export const resetImpressions = () => {
  sessionStorage.removeItem("impressionId");
  sessionStorage.removeItem("impressionStartTimestamp");
  sessionStorage.removeItem("impressionEndTimestamp");
  sessionStorage.removeItem("isLive");
  sessionStorage.removeItem("isSelfie");
  sessionStorage.removeItem("isModel");
  sessionStorage.removeItem("modelIndex");
};

export const setImpressionStartItems = () => {
  sessionStorage.setItem("impressionId", generateOID());
  sessionStorage.setItem("impressionStartTimestamp", getStandardTimeStamp());
};

export const impressionEndEvent = () => {
  sessionStorage.setItem("impressionEndTimestamp", getStandardTimeStamp());
  transmitEvent("impression", {
    sessionId: sessionStorage.sessionId,
    impressionId: sessionStorage.impressionId,
    isLive: JSON.parse(sessionStorage.isLive),
    isModel: JSON.parse(sessionStorage.isModel),
    isSelfie: JSON.parse(sessionStorage.isSelfie),
    modelIndex: sessionStorage.modelIndex
      ? Number(sessionStorage.modelIndex)
      : undefined,
    impressionStartTimestamp: sessionStorage.impressionStartTimestamp,
    impressionEndTimestamp: sessionStorage.impressionEndTimestamp
  });
  resetImpressions();
};

export const tryoutEvent = (item: MakeUpDataType) => {
  const tryoutId = generateOID();
  transmitEvent("tryon", {
    sessionId: sessionStorage.sessionId,
    impressionId: sessionStorage.impressionId,
    tryoutId,
    skuId: item.sku_id,
    skuName: item.sku_name,
    skuCategory: item.type
  });
};

export const conversionEvent = (item: MakeUpDataType) => {
  const conversionId = generateOID();
  transmitEvent("conversion", {
    sessionId: sessionStorage.sessionId,
    impressionId: sessionStorage.impressionId,
    conversionId,
    skuId: item.sku_id,
    skuName: item.sku_name,
    skuCategory: item.type
  });
};
