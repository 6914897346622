import React, { Component } from "react";
import history from "redux/history";
import { Route, Router, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { Store } from "redux";
import { IntlProvider } from "react-intl";

import { ActionType } from "types/common";
import messages_en from "translations/en.json";
import messages_ru from "translations/ru.json";
import { ROUTES } from "utils/constants";
import HomePage from "routes/HomePage/HomePage";
import {
  changeVmtStatus,
  selectIsAlgoFaceBrandingRequired,
  selectIsAnalyticRequired,
  selectIsTranslatesRequired,
  selectLanguage
} from "ducks/app";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import ModelsPage from "../ModelsPage/ModelsPage";
import ARPage from "../ARPage/ARPage";
import { initAnalytic } from "ducks/analytics";
import CTABanner from '../../components/CTABanner/CTABanner';

import styles from "./RootRouter.module.scss";

const messages = {
  en: messages_en,
  ru: messages_ru
};

interface Props {
  lang: string;
  isAlgoFaceBrandingRequired: boolean;
  isTranslatesRequired: boolean;
  isAnalyticRequired: boolean;
  changeVmtStatus: (isReady: boolean) => ActionType;
  initAnalytic: () => ActionType;
}

class RootRouter extends Component<Props> {
  public componentDidMount(): void {
    if (this.props.isAnalyticRequired) {
      this.props.initAnalytic();
    }

    document.addEventListener("vmtIsReady", this.changeVmtStatus);
  }

  public componentWillUnmount(): void {
    document.removeEventListener("vmtIsReady", this.changeVmtStatus);
  }

  public changeVmtStatus = () => {
    this.props.changeVmtStatus(true);
  };

  public render() {
    const { lang } = this.props;

    return (
      <IntlProvider
        locale={lang}
        messages={
          this.props.isTranslatesRequired
            ? messages[lang] || messages.en
            : messages.en
        }
      >
        <Router history={history}>
          <div className={styles.root}>
            <Header />
            <Switch>
              <Route path={ROUTES.models} component={ModelsPage} />
              <Route path={ROUTES.tryOn} component={ARPage} />
              <Route path={ROUTES.root} component={HomePage} />
            </Switch>
            <div className={styles.ctaBannerContainer}>
               <CTABanner></CTABanner>
            </div>
            {this.props.isAlgoFaceBrandingRequired && <Footer />}
          </div>
        </Router>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state: Store) => ({
  isAlgoFaceBrandingRequired: selectIsAlgoFaceBrandingRequired(state),
  isTranslatesRequired: selectIsTranslatesRequired(state),
  isAnalyticRequired: selectIsAnalyticRequired(state),
  lang: selectLanguage(state)
});

const mapDispatchToProps = {
  changeVmtStatus,
  initAnalytic
};

export default connect(mapStateToProps, mapDispatchToProps)(RootRouter);
