import { Store } from "redux";
import { createSelector } from "reselect";
import { SagaIterator } from "redux-saga";
import { select, put, all, takeEvery, call } from "redux-saga/effects";
import io from "socket.io-client";

import {
  analyticServerURL,
  appName,
  organisation,
  product
} from "utils/constants";
import { ActionType } from "types/common";
import {
  generateOID,
  generateUUID,
  getStandardTimeStamp,
  impressionEndEvent,
  setImpressionStartItems,
  setImpressionType
} from "utils/helpers";

/* Constants */

export const moduleName = "analytics";
const prefix = `${appName}/${moduleName}`;

export const INIT_ANALYTIC = `${prefix}/INIT_ANALYTIC`;
export const INIT_IMPRESSION = `${prefix}/INIT_IMPRESSION`;
export const GENERATE_USER = `${prefix}/GENERATE_USER`;

/* Reducer */

interface State {
  userId: string;
  error: null;
}

const initialState: State = {
  userId: "",
  error: null
};

export default function reducer(
  state: State = initialState,
  action: ActionType
) {
  const { type, payload, error } = action;

  switch (type) {
    case GENERATE_USER:
      return Object.assign({}, state, {
        error: null,
        userId: payload
      });

    default:
      return state;
  }
}

/*Selectors */

const stateSelector = (state: Store) => state[moduleName];

export const selectUser = createSelector(
  stateSelector,
  (state: State) => state.userId
);

/* Action Creators */

export function initAnalytic() {
  return {
    type: INIT_ANALYTIC
  };
}
export function initImpression(payload: string) {
  return {
    type: INIT_IMPRESSION,
    payload
  };
}
export function generateUser(payload: string) {
  return {
    type: GENERATE_USER,
    payload
  };
}

/* Sagas */

function* initAnalyticSaga(): SagaIterator {
  // User
  const user = yield select(selectUser);
  if (!user) {
    yield put(generateUser(generateUUID()));
  }
  sessionStorage.setItem("user", user);

  //Session
  if (sessionStorage.length === 0) {
    sessionStorage.setItem("sessionId", generateOID());
    sessionStorage.setItem("sessionStartTimestamp", getStandardTimeStamp());
  }

  window.addEventListener("beforeunload", event => {
    // when impression is ended
    if (sessionStorage.getItem("impressionStartTimestamp")) {
      impressionEndEvent();
    }

    const sessionObj = {
      sessionId: sessionStorage.sessionId,
      sessionStartTimestamp: sessionStorage.sessionStartTimestamp,
      sessionEndTimestamp: getStandardTimeStamp()
    };

    transmitEvent("session", sessionObj);
    // Cancel the event as stated by the standard.
    event.preventDefault();
    // Chrome requires returnValue to be set.
    event.returnValue = "";
  });

  // Sockets
  // localStorage.debug = "*";
  window.socket = io(analyticServerURL, { transports: ["polling"] });
}

function* initImpressionSaga({ payload }: ActionType): SagaIterator {
  yield call(setImpressionType, payload);
  yield call(setImpressionStartItems);
}

export const saga = function*() {
  yield all([
    takeEvery(INIT_ANALYTIC, initAnalyticSaga),
    takeEvery(INIT_IMPRESSION, initImpressionSaga)
  ]);
};

//API
export function transmitEvent(type: string, payload: any) {
  const transmitEventObj: any = {
    userRef: sessionStorage.user,
    orgRef: organisation,
    productRef: product,
    eventTimestamp: getStandardTimeStamp(),
    type: type.toUpperCase()
  };
  window.socket.emit(
    type.toLowerCase(),
    Object.assign(transmitEventObj, payload)
  );
  return;
}
