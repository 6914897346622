import React, { Component } from "react";
import { connect } from "react-redux";
import { Store } from "redux";
import { Icon, Message } from "semantic-ui-react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { RouteComponentProps, withRouter } from "react-router";
import { Range } from "react-input-range";

import {
  changeIsLiveStatus,
  changeZoomValue,
  downloadImage,
  initCanvas,
  resetCanvasView,
  selectCanvasError,
  selectCurrentModel,
  selectIsAnalyticRequired,
  selectIsLive,
  selectIsSplitterActive,
  selectZoomValue,
  setIntensity,
  toggleMultiCamera,
  toggleSplitter
} from "ducks/app";
import { ROUTES } from "utils/constants";
import { ActionType, ErrorType, ModelDataType } from "types/common";
import { resetMakeUpStatus } from "ducks/makeUp";
import CanvasControls from "components/CanvasControls/CanvasControls";
import { impressionEndEvent } from "utils/helpers";

import styles from "./CanvasAR.module.scss";

interface Props extends RouteComponentProps {
  model: ModelDataType | null;
  isResetVisible: boolean;
  isAnalyticRequired: boolean;
  isSplitterActive: boolean;
  isLive: boolean;
  zoomValue: number;
  error: ErrorType | null;
  resetCanvasView: (payload?: boolean) => ActionType;
  toggleMultiCamera: () => ActionType;
  initCanvas: (payload: ModelDataType | null) => ActionType;
  changeIsLiveStatus: (status: boolean) => ActionType;
  changeZoomValue: (payload: number) => ActionType;
  setIntensity: (payload: number) => ActionType;
  toggleSplitter: (payload: boolean) => ActionType;
  downloadImage: (payload: {
    outputCanvas: HTMLCanvasElement;
    actualWidth: number;
    splitterWidth: number;
  }) => ActionType;
}

interface State {
  outputCanvasWidth: string;
  splitterCanvasWidth: number;
  splitterWidth: number;
}

class CanvasAR extends Component<Props, State> {
  public static defaultProps = {
    isResetVisible: true
  };

  private readonly splitterRef: React.RefObject<HTMLDivElement>;
  private readonly inputCanvasRef: React.RefObject<HTMLCanvasElement>;
  private readonly outputCanvasRef: React.RefObject<HTMLCanvasElement>;

  constructor(props: Props) {
    super(props);

    this.state = {
      outputCanvasWidth: "100%",
      splitterCanvasWidth: 0,
      splitterWidth: 0
    };

    this.splitterRef = React.createRef();
    this.inputCanvasRef = React.createRef();
    this.outputCanvasRef = React.createRef();
  }

  public componentDidMount() {
    window.addEventListener("resize", this.changeCanvasWidth);
    window.addEventListener("orientationchange", this.changeCanvasWidth);
    document.addEventListener("canvasRendered", this.changeCanvasWidth);

    const { model } = this.props;
    this.props.initCanvas(model);

    this.splitterRef.current?.addEventListener(
      "mousedown",
      this.onSplitterDragStart
    );
    this.splitterRef.current?.addEventListener(
      "touchstart",
      this.onSplitterDragStart
    );
  }

  public componentWillUnmount(): void {
    this.props.resetCanvasView();
    window.removeEventListener("resize", this.changeCanvasWidth);
    window.removeEventListener("orientationchange", this.changeCanvasWidth);
    document.removeEventListener("canvasRendered", this.changeCanvasWidth);
  }

  public changeCanvasWidth = () => {
    const maxHeight =
      window.innerHeight * (window.innerWidth > 767 ? 0.75 : 1) - 125;
    const inputCanvasHeight = this.inputCanvasRef.current?.offsetHeight || 0;
    const inputCanvasWidth = this.inputCanvasRef.current?.offsetWidth || 0;
    const outputCanvasWidth = "100%";

    if (inputCanvasHeight > maxHeight) {
      const aspect = inputCanvasWidth / inputCanvasHeight;
      const maxWidth = maxHeight * aspect;

      if (this.state.outputCanvasWidth !== maxWidth + "px") {
        this.setState({
          outputCanvasWidth: maxWidth + "px",
          splitterCanvasWidth: maxWidth,
          splitterWidth: maxWidth / 2
        });
      }
    } else {
      if (this.state.outputCanvasWidth !== outputCanvasWidth) {
        this.setState({
          outputCanvasWidth,
          splitterCanvasWidth: inputCanvasWidth,
          splitterWidth: inputCanvasWidth / 2
        });
      } else {
        this.setState({
          splitterCanvasWidth: inputCanvasWidth,
          splitterWidth: inputCanvasWidth / 2
        });
      }
    }
  };

  public onSplitterDragStart = (event: MouseEvent | TouchEvent) => {
    event.stopPropagation();
    window.addEventListener("mousemove", this.onSplitterDragProgress);
    window.addEventListener("mouseup", this.onSplitterDragEnd);
    window.addEventListener("touchmove", this.onSplitterDragProgress);
    window.addEventListener("touchend", this.onSplitterDragEnd);
  };

  public onSplitterDragProgress = (event: MouseEvent | TouchEvent) => {
    if ("clientX" in event && event?.clientX) {
      const mousePositionX =
        event.clientX -
        this.outputCanvasRef?.current?.getBoundingClientRect().left!;
      if (
        mousePositionX > 0 &&
        this.state.splitterCanvasWidth > mousePositionX
      ) {
        this.setState({ splitterWidth: mousePositionX });
      }
    } else if ("touches" in event && event?.touches[0]) {
      const fingerPosition = event.touches[0].clientX - 30;
      if (
        fingerPosition > 0 &&
        this.state.splitterCanvasWidth > fingerPosition
      ) {
        this.setState({ splitterWidth: fingerPosition });
      }
    }
  };

  public onSplitterDragEnd = () => {
    window.removeEventListener("mousemove", this.onSplitterDragProgress);
    window.removeEventListener("mouseup", this.onSplitterDragEnd);
    window.removeEventListener("touchmove", this.onSplitterDragProgress);
    window.removeEventListener("touchend", this.onSplitterDragEnd);
  };

  public changeZoom = (value: number) => () => {
    this.props.changeZoomValue(value);
  };

  public setIntensity = (value: number | Range) => {
    this.props.setIntensity(value as number);
  };

  public toggleSplitter = () => {
    this.props.toggleSplitter(!this.props.isSplitterActive);
  };

  public toggleCamera = () => {
    this.props.toggleMultiCamera();
  };

  public downloadImage = () => {
    const outputCanvas = this.inputCanvasRef.current!;
    const actualWidth = this.state.splitterCanvasWidth;
    const splitterWidth = this.state.splitterWidth;
    this.props.downloadImage({
      outputCanvas,
      actualWidth,
      splitterWidth
    });
  };

  public resetView = () => {
    this.setState({ splitterWidth: this.state.splitterCanvasWidth / 2 });
    this.props.resetCanvasView(false);
  };

  public render() {
    const { isSplitterActive, error } = this.props;
    return (
      <>
        {!!error && <Message negative={true}>{error.message}</Message>}
        <div className={styles.root}>
          <div className={styles.canvasWrapper}>
            <canvas
              ref={this.inputCanvasRef}
              id="input_canvas"
              className={styles.inputCanvas}
            />
            {this.props.isLive && (
              <video id="video" className={styles.videoLive} />
            )}
            <canvas
              id="output_canvas"
              ref={this.outputCanvasRef}
              style={{
                width: this.state.outputCanvasWidth
              }}
              className={styles.outputCanvas}
            />
            <div
              className={styles.splitterWrapper}
              style={{
                width: this.state.outputCanvasWidth
              }}
            >
              <div
                className={classNames(styles.inputCanvasContainer, {
                  [styles.active]: isSplitterActive
                })}
                style={{ width: this.state.splitterWidth }}
              >
                <canvas
                  style={{
                    width: this.state.splitterCanvasWidth + "px"
                  }}
                  id="canvas_splitter"
                  className={styles.splitterCanvas}
                />
              </div>
              <div
                ref={this.splitterRef}
                className={classNames(styles.splitter, {
                  [styles.active]: isSplitterActive
                })}
                style={{ left: this.state.splitterWidth }}
              />
            </div>
          </div>
          <div className={styles.closeButtonContainer}>
            <Link
              to={ROUTES.root}
              onClick={
                this.props.isAnalyticRequired ? impressionEndEvent : undefined
              }
            >
              <div className={styles.closeButton}>
                <Icon name="close" />
              </div>
            </Link>
          </div>
          <CanvasControls
            isResetVisible={this.props.isResetVisible}
            resetView={this.resetView}
            changeZoom={this.changeZoom}
            toggleSplitter={this.toggleSplitter}
            downloadImage={this.downloadImage}
            toggleCamera={this.toggleCamera}
            setIntensity={this.setIntensity}
            isSplitterActive={isSplitterActive}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: Store) => ({
  isAnalyticRequired: selectIsAnalyticRequired(state),
  model: selectCurrentModel(state),
  isLive: selectIsLive(state),
  zoomValue: selectZoomValue(state),
  isSplitterActive: selectIsSplitterActive(state),
  error: selectCanvasError(state)
});

const mapDispatchToProps = {
  changeIsLiveStatus,
  resetMakeUpStatus,
  setIntensity,
  toggleSplitter,
  downloadImage,
  changeZoomValue,
  resetCanvasView,
  initCanvas,
  toggleMultiCamera
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CanvasAR)
);
